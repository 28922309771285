import { Helmet } from 'react-helmet-async';
import { useLocales } from '../../../locales';
import { useAuthContext } from '../../../auth/hooks';
import NoAccessPage from '../../../sections/access/NoAccess';
import EntityView from '../../../sections/entity/EntityView';

export default function EntitiesViewPage() {
  const { t } = useLocales();

  const authContext = useAuthContext();
  const canAccess = ['ADMIN', 'MANAGER'].includes(authContext?.user?.role);

  if (!canAccess) {
    return <NoAccessPage />;
  }

  return (
    <>
      <Helmet>
        <title>
          {t('seo.entitiesView.title')} - {t('appName')}
        </title>
        <meta name="description" content={t('seo.entitiesView.description')} />
      </Helmet>

      <EntityView />
    </>
  );
}
