import axios from 'axios';
// config
import {HOST_API} from 'src/config-global';
import {setSession} from "../auth/context/jwt/utils";

// ----------------------------------------------------------------------

const axiosInstance = axios.create({baseURL: HOST_API});

axiosInstance.interceptors.request.use(
	(config) => {
		const token = sessionStorage.getItem('accessToken');
		config.headers['Accept-Language'] = localStorage.getItem('i18nextLng') || 'en';
		if (token) {
			config.headers.Authorization = `Bearer ${token}`;

		}
		return config;
	},
	(error) => {
		Promise.reject(error);
	}
);

axiosInstance.interceptors.response.use(
	(res) => {
		const {accessToken} = res.data;

		if (accessToken) {
			setSession(accessToken);
		}
		return res;
	},
	(error) => Promise.reject((error.response && error.response.data) || 'Something went wrong')
);

export default axiosInstance;

// ----------------------------------------------------------------------

export const fetcher = async (args) => {
	const [url, config] = Array.isArray(args) ? args : [args];
	const res = await axiosInstance.get(url, {...config});
	return res.data;
};

// ----------------------------------------------------------------------

const version = 'v1';

export const endpoints = {
	attributes: {
		services: `/${version}/attributes/services`,
		employees: `/${version}/attributes/employees`,
	},
	files: {
		find: `/${version}/files`,
		upload: `/${version}/files`,
		delete: (id) => `/${version}/files/${id}`,
		deleteBulk: `/${version}/files/remove/bulk`,
		read: (id) => `/${version}/files/${id}`,
		signedUrl: (id) => `/${version}/files/${id}/signed`,
	},
	aiOperator: {
		configuration: `/${version}/virtual-operator/configuration`,
		generate: `/${version}/virtual-operator/configuration/generate`,
	},
	entityConfigurations: {
		find: `/${version}/entity-configurations`,
		findSelectable: `/${version}/entity-configurations/selectable`,
		create: `/${version}/entity-configurations`,
		type: (type) => `/${version}/entity-configurations/type/${type.replace(/-/g, ' ')}`,
		patch: (id) => `/${version}/entity-configurations/${id}`,
		get: (id) => `/${version}/entity-configurations/${id}`,
		delete: (id) => `/${version}/entity-configurations/${id}`,
		deleteBulk: `/${version}/entity-configurations/remove/bulk`,
	},
	entities: {
		find: (entity) => `/${version}/entities/${entity.replace(/-/g, ' ')}`,
		create: (entity) => `/${version}/entities/${entity.replace(/-/g, ' ')}`,
		patch: (entity, id) => `/${version}/entities/${entity.replace(/-/g, ' ')}/${id}`,
		get: (entity, id) => `/${version}/entities/${entity.replace(/-/g, ' ')}/${id}`,
		view: (entity, id) => `/${version}/entities/${entity.replace(/-/g, ' ')}/view/${id}`,
		delete: (entity, id) => `/${version}/entities/${entity.replace(/-/g, ' ')}/${id}`,
		deleteBulk: (entity) => `/${version}/entities/${entity.replace(/-/g, ' ')}/remove/bulk`,
	},
	halls: {
		find: `/${version}/halls`,
		create: `/${version}/halls`,
		patch: (id) => `/${version}/halls/${id}`,
		get: (id) => `/${version}/halls/${id}`,
		view: (id) => `/${version}/halls/view/${id}`,
		delete: (id) => `/${version}/halls/${id}`,
		deleteBulk: `/${version}/halls/remove/bulk`,
	},
	customAttributes: {
		appointment: {
			get: (field) => `/${version}/custom-attributes/appointment/${field}`,
			create: `/${version}/custom-attributes/appointment`,
			find: `/${version}/custom-attributes/appointment`,
			delete: (field) => `/${version}/custom-attributes/appointment/${field}`,
			deleteBulk: `/${version}/custom-attributes/appointment/bulk`,
			patch: (field) => `/${version}/custom-attributes/appointment/${field}`,
		},
		employee: {
			get: (field) => `/${version}/custom-attributes/employee/${field}`,
			create: `/${version}/custom-attributes/employee`,
			find: `/${version}/custom-attributes/employee`,
			delete: (field) => `/${version}/custom-attributes/employee/${field}`,
			deleteBulk: `/${version}/custom-attributes/employee/bulk`,
			patch: (field) => `/${version}/custom-attributes/employee/${field}`,
		},
		service: {
			get: (field) => `/${version}/custom-attributes/service/${field}`,
			create: `/${version}/custom-attributes/service`,
			find: `/${version}/custom-attributes/service`,
			delete: (field) => `/${version}/custom-attributes/service/${field}`,
			deleteBulk: `/${version}/custom-attributes/service/bulk`,
			patch: (field) => `/${version}/custom-attributes/service/${field}`,
		},
	 customer: {
		 get: (field) => `/${version}/custom-attributes/customer/${field}`,
		 create: `/${version}/custom-attributes/customer`,
		 find: `/${version}/custom-attributes/customer`,
		 delete: (field) => `/${version}/custom-attributes/customer/${field}`,
		 deleteBulk: `/${version}/custom-attributes/customer/bulk`,
		 patch: (field) => `/${version}/custom-attributes/customer/${field}`,
	 },
		hall: {
			get: (field) => `/${version}/custom-attributes/hall/${field}`,
			create: `/${version}/custom-attributes/hall`,
			find: `/${version}/custom-attributes/hall`,
			delete: (field) => `/${version}/custom-attributes/hall/${field}`,
			deleteBulk: `/${version}/custom-attributes/hall/bulk`,
			patch: (field) => `/${version}/custom-attributes/hall/${field}`,
		},
	},
	assistant: {
		prompt: `/${version}/assistant/prompt`,
	},
	contact: `/${version}/contact`,
	subscriptions: {
		me: `/${version}/subscriptions/me`,
		quotas: `/${version}/subscriptions/quotas`,
		upgradePreview: (planId) => `/${version}/subscriptions/upgrade/${planId}/preview`,
		upgrade: (planId) => `/${version}/subscriptions/upgrade/${planId}`,
		paymentStatus: `/${version}/subscriptions/payments/status`,
		addNewPaymentMethod: `/${version}/subscriptions/payments/method`,
		paymentMethod: `/${version}/subscriptions/payments/method`,
		paymentMethodRemove: `/${version}/subscriptions/payments/method/remove`,
		paymentInvoices: `/${version}/subscriptions/payments/invoices`,
	},
	plans: {
		find: `/${version}/plan`,

	},
	templates: {
		get: (type, name) => `/${version}/templates/${type}/${name}`,
		update: (type, name) => `/${version}/templates/${type}/${name}`,
	},
	appointment: {
		cancellation: `/${version}/appointment/cancellation`,
	},
	customers: {
		find: `/${version}/customers`,
		create: `/${version}/customers`,
		appointments: (id) => `/${version}/customers/${id}/appointments`,
		patch: (id) => `/${version}/customers/${id}`,
		get: (id) => `/${version}/customers/${id}`,
		view: (id) => `/${version}/customers/view/${id}`,
		delete: (id) => `/${version}/customers/${id}`,
		deleteBulk: `/${version}/customers/remove/bulk`,
	},
	services: {
		find: `/${version}/services`,
		create: `/${version}/services`,
		patch: (id) => `/${version}/services/${id}`,
		get: (id) => `/${version}/services/${id}`,
		view: (id) => `/${version}/services/view/${id}`,
		delete: (id) => `/${version}/services/${id}`,
		deleteBulk: `/${version}/services/remove/bulk`,
	},
	employees: {
		find: `/${version}/employees`,
		create: `/${version}/employees`,
		patch: (id) => `/${version}/employees/${id}`,
		get: (id) => `/${version}/employees/${id}`,
		view: (id) => `/${version}/employees/view/${id}`,
		delete: (id) => `/${version}/employees/${id}`,
		deleteBulk: `/${version}/employees/remove/bulk`,
	},
	configurations: {
		get: `/${version}/configurations`,
		find: `/${version}/configurations`,
		update: `/${version}/configurations`,
		logo: `/${version}/configurations/logo`,
	},
	clientsAttributes: `/${version}/client/attributes`,
	clientTableConfiguration: `/${version}/client/table-configuration`,
	chat: `/${version}/api/chat`,
	kanban: `/${version}/api/kanban`,
	calendar: {
		freeSlots: `/${version}/calendar/appointments/free-slots`,
		freeDays: `/${version}/calendar/appointments/free-days`,
		create: `/${version}/calendar/appointments`,
		find: `/${version}/calendar/appointments`,
		get: (id) => `/${version}/calendar/appointments/${id}`,
		getPopulateCustomers: (id) => `/${version}/calendar/appointments/${id}?populateCustomers=true`,
		patch: (id) => `/${version}/calendar/appointments/${id}`,
		patchCustomers: (id) => `/${version}/calendar/appointments/${id}/customers`,
		delete: (id) => `/${version}/calendar/appointments/${id}`,
		cancel: (id) => `/${version}/calendar/appointments/${id}/cancel`,
		logs: (id) => `/${version}/calendar/appointments/${id}/logs`,
	},
	auth: {
		me: `/${version}/auth/me`,
		login: `/${version}/auth/login`,
		register: `/${version}/auth/register`,
		activate: `/${version}/auth/activate`,
		resetPassword: `/${version}/auth/reset-password`,
		resetPasswordChange: `/${version}/auth/reset-password/change`,
		resendActivationCode: `/${version}/auth/regenerate-activation-code`,
	},
	mail: {
		list: `/${version}/mail/list`,
		details: `/${version}/mail/details`,
		labels: `/${version}/mail/labels`,
	},
	post: {
		list: `/${version}/post/list`,
		details: `/${version}/post/details`,
		latest: `/${version}/post/latest`,
		search: `/${version}/post/search`,
	},
	product: {
		list: `/${version}/product/list`,
		details: `/${version}/product/details`,
		search: `/${version}/product/search`,
	},
	organisation: {
		create: `/${version}/organisation`,
		me: `/${version}/organisation/me`,
		taxId: (id) => `/${version}/organisation/tax-id/${id}`,
		select: (id) => `/${version}/organisation/select/${id}`,
		update: (id) => `/${version}/organisation/${id}`,
		configuration: {
			update: `/${version}/organisation/configuration/settings`,
		},
		verification: `/${version}/organisation/verification`,
	},
	user: {
		organisations: `/${version}/user/organisations`
	},
	invitations: {
		get: (id) => `/${version}/invitations/${id}`,
		accept: (id) => `/${version}/invitations/${id}/accept`,
	}
};
