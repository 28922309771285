// @mui
import Container from '@mui/material/Container';
// routes
import { paths } from 'src/routes/paths';
// components
import { useSettingsContext } from 'src/components/settings';
import CustomBreadcrumbs from 'src/components/custom-breadcrumbs';
//
import CustomAttributeCustomerCreateForm from './CustomAttributeCustomerCreateForm';
import { useLocales } from '../../../locales';

export default function CustomAttributesCustomerCreateContainer() {
    const { t } = useLocales();
  const settings = useSettingsContext();

  return (
    <Container maxWidth={settings.themeStretch ? false : 'lg'}>
      <CustomBreadcrumbs
        heading={t('pages.customAttributes.customer.addAttribute.title')}
        links={[
          {
            name: t('breadcrumbs.dashboard'),
            href: paths.dashboard.root,
          },
          {
            name: t('breadcrumbs.customAttributesCustomer'),
            href: paths.dashboard.customAttributes.customer,
          },
          { name: t('buttons.add') },
        ]}
        sx={{
          mb: { xs: 3, md: 5 },
        }}
      />
        <CustomAttributeCustomerCreateForm />
    </Container>
  );
}
