import { Helmet } from 'react-helmet-async';
import { useLocales } from '../../../locales';
import { useAuthContext } from '../../../auth/hooks';
import NoAccessPage from '../../../sections/access/NoAccess';
import NotificationsView from '../../../sections/settings/NotificationsView';
import { canUseFeatures } from '../../../utils/feature-control';
import { Features } from '../../../assets/data/features';

export default function NotificationsPage() {
  const { t } = useLocales();

  const { user, features } = useAuthContext();
  const canAccessFeature = canUseFeatures(features, [Features.SendEmail, Features.SendSms]);
  const hasAdminRole = ['ADMIN', 'MANAGER'].includes(user?.role);
  const canAccess = canAccessFeature && hasAdminRole;

  if (!canAccess) {
    return <NoAccessPage />;
  }
  return (
    <>
      <Helmet>
        <title>
          {t('seo.notifications.title')} - {t('appName')}
        </title>
        <meta name="description" content={t('seo.notifications.description')} />
      </Helmet>

      <NotificationsView />
    </>
  );
}
