import { useLocales } from '../../locales';
import { useSnackbar } from 'src/components/snackbar';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import FormProvider, { RHFSwitch, RHFTextField } from '../../components/hook-form';
import * as Yup from 'yup';
import Typography from '@mui/material/Typography';
import Dialog from '@mui/material/Dialog';
import React, { useState } from 'react';
import RHFDatePicker from '../../components/hook-form/rhf-datepicker';
import Chip from '@mui/material/Chip';
import UnverifiedIcon from '@mui/icons-material/Cancel';
import PendingIcon from '@mui/icons-material/Update';
import VerifiedIcon from '@mui/icons-material/Verified';
import StartVerificationIcon from '@mui/icons-material/Start';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import InputAdornment from '@mui/material/InputAdornment';
import DeleteIcon from '@mui/icons-material/Delete';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import PropTypes from 'prop-types';
import { useAuthContext } from '../../auth/hooks';
import LoadingButton from '@mui/lab/LoadingButton';

const Unverified = () => {
  const { t } = useLocales();
  return (
    <Chip
      label={<Typography variant="h6">{t('subtitles.statusUnverified')}</Typography>}
      color="error"
      icon={<UnverifiedIcon />}
    />
  );
};

const Pending = () => {
  const { t } = useLocales();
  return (
    <Chip
      label={<Typography variant="h6">{t('subtitles.statusPending')}</Typography>}
      color="info"
      icon={<PendingIcon />}
    />
  );
};

const Verified = () => {
  const { t } = useLocales();
  return (
    <Chip
      label={<Typography variant="h6">{t('subtitles.statusVerified')}</Typography>}
      color="success"
      icon={<VerifiedIcon />}
    />
  );
};

const Rejected = () => {
  const { t } = useLocales();
  return (
    <Chip
      label={<Typography variant="h6">{t('subtitles.statusRejected')}</Typography>}
      color="error"
      icon={<UnverifiedIcon />}
    />
  );
};

const RejectedReason = ({ reason }) => {
  const { t } = useLocales();
  return (
    <Chip
      label={<Typography variant="h6">{t('subtitles.rejectedReason', { reason })}</Typography>}
      color="error"
    />
  );
};
RejectedReason.propTypes = {
  reason: PropTypes.string,
};

const StartVerification = ({ onStart }) => {
  const { t } = useLocales();
  return (
    <Button onClick={() => onStart()} variant="contained" startIcon={<StartVerificationIcon />}>
      {t('buttons.startVerification')}
    </Button>
  );
};
StartVerification.propTypes = {
  onStart: PropTypes.func,
};

const FileField = ({ name, label, methods, values }) => {
  const { t } = useLocales();
  const { enqueueSnackbar } = useSnackbar();
  const validateFileType = (file) => {
    const allowedTypes = ['image/jpeg', 'image/png', 'application/pdf'];
    return allowedTypes.includes(file.type);
  };

  const validateFileSize = (file) => {
    const maxSizeInBytes = 5 * 1024 * 1024; // 5 MB
    return file.size <= maxSizeInBytes;
  };

  return (
    <RHFTextField
      fullWidth
      label={label}
      name={name}
      value={methods.getValues(name)?.name || t('fields.noFile')}
      InputLabelProps={{ shrink: true }}
      InputProps={{
        readOnly: true,
        endAdornment: (
          <InputAdornment position="end">
            <Stack spacing={1} direction="row">
              {!!values[name] && (
                <Button
                  variant="outlined"
                  startIcon={<DeleteIcon />}
                  onClick={() => methods.setValue(name, '')}
                >
                  {t('buttons.delete')}
                </Button>
              )}
              {!values[name] && (
                <Button variant="contained" component="label" startIcon={<FileUploadIcon />}>
                  {t('buttons.upload')}
                  <input
                    type="file"
                    hidden
                    onChange={async (event) => {
                      const file = event.target.files[0];
                      if (!file) return;

                      if (!validateFileType(file)) {
                        enqueueSnackbar(t('errors.invalidFileType'), { variant: 'error' });
                        return;
                      }

                      if (!validateFileSize(file)) {
                        enqueueSnackbar(t('errors.invalidFileSize', { size: '5' }), {
                          variant: 'error',
                        });
                        return;
                      }

                      methods.setValue(name, file);
                    }}
                  />
                </Button>
              )}
            </Stack>
          </InputAdornment>
        ),
      }}
    />
  );
};
FileField.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  methods: PropTypes.object.isRequired,
  values: PropTypes.object.isRequired,
};

export default function OrganisationVerification() {
  const { t } = useLocales();
  const { enqueueSnackbar } = useSnackbar();
  const [open, setOpen] = useState(false);
  const { configuration, submitVerification } = useAuthContext();

  const [loading, setLoading] = useState(false);

  const Schema = Yup.object().shape({
    isIndividual: Yup.boolean().required(),
    businessName: Yup.string().when('isIndividual', {
      is: false,
      then: () => Yup.string().required(),
      otherwise: () => Yup.string().nullable(),
    }),
    firstName: Yup.string().required(),
    lastName: Yup.string().required(),
    dob: Yup.date().required(),
    identityDocument: Yup.mixed().required(),
    tradeRegistryDocument: Yup.mixed().when('isIndividual', {
      is: false,
      then: () => Yup.mixed().required(),
      otherwise: () => Yup.mixed().nullable(),
    }),
    authorizationDocument: Yup.mixed().when('isIndividual', {
      is: false,
      then: () => Yup.mixed().required(),
      otherwise: () => Yup.mixed().nullable(),
    }),
  });

  const methods = useForm({
    resolver: yupResolver(Schema),
    defaultValues: {
      isIndividual: false,
      businessName: '',
      firstName: '',
      lastName: '',
      dob: null,
      identityDocument: null,
      tradeRegistryDocument: null,
      authorizationDocument: null,
    },
  });

  const values = methods.watch();

  const { handleSubmit } = methods;

  const submit = handleSubmit(
    async (data) => {
      try {
        setLoading(true);
        const formData = new FormData();

        formData.append('isIndividual', data.isIndividual);
        if (data.businessName) {
          formData.append('businessName', data.businessName);
        }
        formData.append('firstName', data.firstName);
        formData.append('lastName', data.lastName);
        formData.append('dateOfBirth', data.dob?.toISOString());

        if (data.identityDocument) {
          formData.append('identityDocument', data.identityDocument);
        }
        if (data.tradeRegistryDocument) {
          formData.append('tradeRegistryDocument', data.tradeRegistryDocument);
        }
        if (data.authorizationDocument) {
          formData.append('authorizationDocument', data.authorizationDocument);
        }

        await submitVerification(formData);

        enqueueSnackbar(t('messages.verificationSubmitted'), { variant: 'success' });
        setLoading(false);
        setOpen(false);
      } catch (error) {
        console.error('Error submitting verification:', error);
        enqueueSnackbar(t('errors.submissionFailed'), { variant: 'error' });
        setLoading(false);
      }
    },
    (error) => {
      setLoading(false);
      console.error('Validation errors:', error);
    }
  );

  const status = configuration?.verification?.status;
  const isBusiness = !values.isIndividual;

  return (
    <>
      {status === 'unverified' && (
        <Stack spacing={2} direction="row">
          <Unverified />
          <StartVerification onStart={() => setOpen(true)} />
        </Stack>
      )}
      {status === 'pending' && <Pending />}
      {status === 'verified' && <Verified />}
      {status === 'rejected' && (
        <Stack spacing={2} direction="column">
          <Stack spacing={2} direction="row">
            <Rejected />
            <StartVerification onStart={() => setOpen(true)} />
          </Stack>
          <RejectedReason reason={configuration?.verification?.rejectedReason} />
        </Stack>
      )}
      <Typography variant="body2" sx={{ mt: 2 }}>
        {t('components.pricing.businessVerificationRequired.explanation')}
      </Typography>

      <Dialog open={open} onClose={() => setOpen(false)} maxWidth="md" fullWidth>
        <DialogTitle>Verification</DialogTitle>
        <DialogContent>
          <FormProvider methods={methods} onSubmit={submit}>
            <Stack spacing={1}>
              <RHFSwitch name="isIndividual" label={t('fields.notBusiness')} />
              {isBusiness && <RHFTextField name="businessName" label={t('fields.businessName')} />}
              <Stack direction="row" spacing={1}>
                <RHFTextField name="firstName" label={t('fields.firstName')} />
                <RHFTextField name="lastName" label={t('fields.lastName')} />
              </Stack>
              <RHFDatePicker name="dob" label={t('fields.dob')} />
            </Stack>

            <Typography variant="subtitle1" sx={{ mt: 4 }}>
              {t('titles.documents')}
            </Typography>
            <Typography variant="body2" sx={{ mb: 1 }}>
              {t('subtitles.verificationFileRequirements')}
            </Typography>
            <Stack direction="column" spacing={1}>
              <FileField name="identityDocument" label={t('fields.identityDocument')} methods={methods} values={values} />
              {isBusiness && (
                <FileField name="tradeRegistryDocument" label={t('fields.tradeRegistryDocument')} methods={methods} values={values} />
              )}
              {isBusiness && (
                <FileField name="authorizationDocument" label={t('fields.authorizationDocument')} methods={methods} values={values} />
              )}
            </Stack>
          </FormProvider>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)}>{t('buttons.cancel')}</Button>
          <LoadingButton variant="contained" onClick={submit} loading={loading}>
            {t('buttons.submit')}
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  );
}
