import { useMemo, useState } from 'react';
import useSWR from 'swr';
// utils
import axios, { endpoints, fetcher } from 'src/utils/axios';

// ----------------------------------------------------------------------

const URL = endpoints.calendar.find;

const options = {
	revalidateIfStale: false,
	revalidateOnFocus: false,
	revalidateOnReconnect: false,
};

const getEventColor = (event, freeSlots = 0, maximumCapacity = 0) => {
	const filledPercentage = (maximumCapacity - freeSlots) / maximumCapacity * 100;
	if (event.cancelled) {
		return "#000000"
	}
	if (freeSlots === 0) {
		return "#ff4600";
	}
	if (filledPercentage >= 75) {
		return "#ffc400";
	}
	return "#009688";
}

export function useGetEvents() {
	const [viewportData, setViewportData] = useState({start: null, end: null});
	const [eventFilters, setEventFilters] = useState({});

	const params = {
		...viewportData.start && {viewportStart: viewportData.start},
		...viewportData.end && {viewportEnd: viewportData.end},
		...eventFilters,
	}

	const {data, isLoading, error, isValidating, mutate} = useSWR(
		[URL, {params}],
		fetcher,
		options
	);

	const createEvent = async (eventData) => {
		const event = await axios.post(endpoints.calendar.create, eventData);
		await mutate(
			(currentData) => {
				const events = [...currentData.events, event.data];

				return {
					...currentData,
					events,
				};
			},
			false
		);
	}

	const deleteEvent = async (eventId) => {
		await axios.delete(endpoints.calendar.delete(eventId));
		await mutate(
			(currentData) => {
				const events = currentData?.events?.filter((event) => event.id !== eventId);

				return {
					...currentData,
					events,
				};
			},
			false
		);
	}

	const updateEvent = async (eventData) => {
		await axios.patch(endpoints.calendar.patch(eventData.id), eventData);
		await mutate(
			(currentData) => {
				const events = currentData?.events?.map((event) =>
					event.id === eventData.id ? {...event, ...eventData} : event
				);

				return {
					...currentData,
					events,
				};
			},
			false
		);
	}

	const cancelEvent = async (eventId) => {
		await axios.patch(endpoints.calendar.cancel(eventId));
		await mutate(
			(currentData) => {
				const events = currentData?.events?.map((event) =>
					event.id === eventId ? {...event, cancelled: true} : event
				);

				return {
					...currentData,
					events,
				};
			},
			false
		);
	}

	return useMemo(() => {
		const events = data?.events?.map((event) => ({
			...event,
			start: event.start * 1000,
			end: event.end * 1000,
			textColor: getEventColor(event, event?.freeSlots, event?.maximumCapacity),
		}));

		return {
			setViewportData,
			setEventFilters,
			events: events || [],
			eventsLoading: isLoading,
			eventsError: error,
			eventsValidating: isValidating,
			eventsEmpty: !isLoading && !data?.events?.length,
			createEvent,
			deleteEvent,
			updateEvent,
			cancelEvent,
		};
	}, [data?.events, error, isLoading, isValidating, setViewportData, setEventFilters]);
}

// ----------------------------------------------------------------------
