import {Helmet} from 'react-helmet-async';
import {useLocales} from "../../../locales";
import {useAuthContext} from "../../../auth/hooks";
import NoAccessPage from "../../../sections/access/NoAccess";
import EntityCreateContainer from "../../../sections/entity/create/EntityCreateContainer";
import {useParams} from "../../../routes/hook";
import {capitalize} from "@mui/material";
import {formatEntityName} from "../../../sections/entity/create/EntityCreateForm";
import { canUseFeature } from '../../../utils/feature-control';
import { Features } from '../../../assets/data/features';

export default function EntityNew() {
	const {t} = useLocales();
	const params = useParams();
	const { entity: rawEntity } = params;
	const entity = formatEntityName(rawEntity);

	const { user, features } = useAuthContext();
	const canAccessFeature = canUseFeature(features, Features.Entities);
	const hasAdminRole = ['ADMIN', 'MANAGER'].includes(user?.role)
	const canAccess = canAccessFeature && hasAdminRole;

	if (!canAccess) {
		return (
			<NoAccessPage/>
		);
	}

	return (
		<>
			<Helmet>
				<title>{capitalize(entity)} - {t('appName')}</title>
				<meta name="description" content={t('seo.entitiesAdd.description')}/>
			</Helmet>

			<EntityCreateContainer/>
		</>
	);
}
