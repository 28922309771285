import { useLocales } from '../../locales';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import Button from '@mui/material/Button';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { copyToClipboard } from '../../utils/basic';
import PropTypes from 'prop-types';
import React from 'react';
import Stack from '@mui/material/Stack';
import { paths } from '../../routes/paths';
import { useRouter } from '../../routes/hook';
import Autocomplete from '@mui/material/Autocomplete';
import Chip from '@mui/material/Chip';
import LinkIcon from '@mui/icons-material/Link';
import Tooltip from '@mui/material/Tooltip';
import FileOpenIcon from '@mui/icons-material/FileOpen';

const getEntityViewLink = (entity, id) => {
  switch (entity) {
    case 'employee':
      return paths.dashboard.employees.view(id);
    case 'customer':
      return paths.dashboard.customers.view(id);
    case 'service':
      return paths.dashboard.services.view(id);
    case 'hall':
      return paths.dashboard.halls.view(id);
    default:
      return paths.dashboard.entities.view(entity, id);
  }
};

export const ViewField = ({
  label,
  value,
  endAdornment,
  copyText,
  attributeConfiguration,
  ...other
}) => {
  const { t } = useLocales();
  const router = useRouter();

  if (attributeConfiguration?.type === 'entity') {
    return (
      <TextField
        label={label}
        value={value?.label}
        InputLabelProps={{ shrink: true }}
        InputProps={{
          readOnly: true,
          endAdornment: (
            <InputAdornment position="end">
              <Stack direction="row" spacing={2}>
                {endAdornment}
                {value?.id && <Button
                  variant="outlined"
                  startIcon={<LinkIcon />}
                  onClick={() =>
                    router.push(getEntityViewLink(attributeConfiguration.entity, value.id))
                  }
                >
                  {t('buttons.view')}
                </Button>}
              </Stack>
            </InputAdornment>
          ),
        }}
        {...other}
      />
    );
  }

  if (attributeConfiguration?.type === 'entities') {
    return (
      <Autocomplete
        multiple
        options={value?.values || []}
        value={value?.values || []}
        getOptionLabel={(option) => option.label}
        isOptionEqualToValue={(option, v) => option.id === v.id}
        renderTags={(v) => (
          <Stack direction="row" spacing={1}>
            {v.map((option) => (
              <Tooltip title={t('tooltips.viewEntity')}>
                <Chip
                  icon={<LinkIcon />}
                  variant="outlined"
                  key={option.id}
                  label={option.label}
                  onClick={() =>
                    router.push(getEntityViewLink(attributeConfiguration.entity, option.id))
                  }
                />
              </Tooltip>
            ))}
          </Stack>
        )}
        renderInput={(params) => <TextField {...params} label={label} />}
        disableClearable
        disabled
        componentsProps={{
          popupIndicator: { style: { display: 'none' } },
        }}
      />
    );
  }

  if (attributeConfiguration?.type === 'file') {
    return (
      <TextField
        label={label}
        value={value.label}
        InputLabelProps={{ shrink: true }}
        InputProps={{
          readOnly: true,
          endAdornment: (
            <InputAdornment position="end">
              <Stack direction="row" spacing={2}>
                {endAdornment}
                <Button
                  variant="outlined"
                  startIcon={<ContentCopyIcon />}
                  onClick={() => copyToClipboard(value.signedUrl)}
                >
                  {t('buttons.copyURL')}
                </Button>
                <Button
                  variant="outlined"
                  startIcon={<FileOpenIcon />}
                  onClick={() => window.open(value.signedUrl, "_blank", "noopener,noreferrer")}
                >
                  {t('buttons.open')}
                </Button>
              </Stack>
            </InputAdornment>
          ),
        }}
        {...other}
      />
    )
  }

  return (
    <TextField
      label={label}
      value={value}
      InputLabelProps={{ shrink: true }}
      InputProps={{
        readOnly: true,
        endAdornment: (
          <InputAdornment position="end">
            <Stack direction="row" spacing={2}>
              {endAdornment}
              <Button
                variant="outlined"
                startIcon={<ContentCopyIcon />}
                onClick={() => copyToClipboard(copyText || value)}
              >
                {t('buttons.copy')}
              </Button>
            </Stack>
          </InputAdornment>
        ),
      }}
      {...other}
    />
  );
};
ViewField.propTypes = {
  label: PropTypes.string,
  value: PropTypes.string,
  endAdornment: PropTypes.node,
  copyText: PropTypes.string,
  attributeConfiguration: PropTypes.object,
};
