import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Unstable_Grid2';
import InputAdornment from '@mui/material/InputAdornment';
import { useSnackbar } from 'src/components/snackbar';
import { RHFAutocomplete, RHFSelect, RHFTextField } from 'src/components/hook-form';
import React, { useCallback, useEffect, useState } from 'react';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import DeleteIcon from '@mui/icons-material/Delete';
import { useLocales } from '../../locales';
import axios, { endpoints } from '../../utils/axios';
import { AttributeTypesEnum } from '../../data/attribute';
import RHFDatePicker from '../hook-form/rhf-datepicker';
import RHFDateTimePicker from '../hook-form/rhf-datetimepicker';

const defaultValues = {
  attributes: {},
};

export default function CustomAttributes({ current, entityConfiguration }) {
  const { t } = useLocales();

  const [files, setFiles] = useState([]);
  const [entityOptions, setEntityOptions] = useState({}); // Stores o

  const { enqueueSnackbar } = useSnackbar();

  const handleFileFetch = useCallback(() => {
    if (current?.attributes) {
      const fileEntities = entityConfiguration?.attributes?.filter((attr) => attr.type === 'file');
      try {
        const fetchFile = async (attr, fileId) => {
          const response = await axios.get(endpoints.files.read(fileId));
          setFiles((prevFiles) => [
            ...prevFiles,
            {
              ...response.data,
              field: attr.field,
            },
          ]);
        };
        Promise.all(fileEntities.map((attr) => fetchFile(attr, current.attributes[attr.field])));
      } catch (e) {
        console.error(e);
      }
    }
  }, [entityConfiguration?.attributes, current?.attributes]);

  useEffect(() => {
    if (entityConfiguration) {
      handleFileFetch();
    }
  }, [entityConfiguration, handleFileFetch]);

  const Schema = Yup.object().shape({
    attributes: Yup.object({
      field: Yup.string(),
      value: Yup.string().max(5000),
    }),
  });

  const methods = useForm({
    resolver: yupResolver(Schema),
    defaultValues: current || defaultValues,
  });


  const fetchEntities = async (entityConfig) => {
    if (entityConfig.isDefault) {
      const response = await axios.get(entityConfig.path);
      return response.data;
    }
    const response = await axios.get(endpoints.entities.find(entityConfig.entity));
    return response.data.map((item) => {
      const attributesObject = item.attributes.reduce((acc, attr) => {
        acc[attr.field] = attr.value;
        return acc;
      }, {});
      return {
        entity: item.entity,
        ...attributesObject,
        active: item.active,
        deleted: item.deleted,
        organisation: item.organisation,
        createdAt: item.createdAt,
        updatedAt: item.updatedAt,
        id: item.id || item._id,
      };
    });
  };

  const getEntityOptions = async (entityConfig) => {
    try {
      const { data: currentEntityConfiguration } = await axios.get(
        endpoints.entityConfigurations.type(entityConfig.entity)
      );
      const primaryAttributesField = currentEntityConfiguration.attributes
        .filter((attr) => attr.primary)
        .map((attr) => attr.field);

      const currentEntities = await fetchEntities(currentEntityConfiguration);

      return currentEntities.map((e) => {
        const label = primaryAttributesField
          .map((field) => e[field])
          .filter((value) => value)
          .join(' | ');

        return {
          label: label || e.id, // Fallback to e.id if no primary attributes are present
          value: e._id || e.id,
        };
      });
    } catch (e) {
      console.error(e);
    }
    return [];
  };

  const fetchEntityOptions = async (entityConfig) => {
    if (entityOptions[entityConfig.field]) return; // Skip if already fetched
    try {
      const options = await getEntityOptions(entityConfig);
      setEntityOptions((prevOptions) => ({
        ...prevOptions,
        [entityConfig.field]: options,
      }));
    } catch (error) {
      console.error('Failed to fetch entity options:', error);
    }
  };

  useEffect(() => {
    if (entityConfiguration?.attributes) {
      entityConfiguration.attributes
        .filter((attr) =>
          [AttributeTypesEnum.entity, AttributeTypesEnum.entities].includes(attr.type)
        )
        .forEach(fetchEntityOptions);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [entityConfiguration]);

  return (
    <form>
      <Grid container spacing={3}>
        <Grid xs={12} md={12}>
          <Box>
            <Stack spacing={2}>
              {entityConfiguration?.attributes
                ?.filter((a) => !['file', 'files'].includes(a.type))
                .map((attr) => {
                  if (attr.type === AttributeTypesEnum.number) {
                    return (
                      <RHFTextField
                        key={attr.name}
                        name={`customAttributes.${attr.field}`}
                        label={attr.name}
                        type="number"
                      />
                    );
                  }

                  if (attr.type === AttributeTypesEnum.dropdown) {
                    return (
                      <RHFSelect
                        fullWidth
                        sx={{ textTransform: 'none' }}
                        key={attr.name}
                        name={`customAttributes.${attr.field}`}
                        label={attr.name}
                      >
                        {attr.options.map((option) => (
                          <MenuItem key={option} value={option}>
                            {option}
                          </MenuItem>
                        ))}
                      </RHFSelect>
                    );
                  }

                  if (attr.type === AttributeTypesEnum.entity) {
                    return (
                      <RHFAutocomplete
                        key={attr.name}
                        name={`customAttributes.${attr.field}`}
                        label={attr.name}
                        getOptionLabel={(option) =>
                          entityOptions[attr.field]?.find((o) => o.value === option)?.label ||
                          option.label
                        }
                        options={entityOptions[attr.field] || []} // Pass options as an array
                      />
                    );
                  }

                  if (attr.type === AttributeTypesEnum.entities) {
                    return (
                      <RHFAutocomplete
                        multiple
                        key={attr.name}
                        name={`customAttributes.${attr.field}`}
                        label={attr.name}
                        isOptionEqualToValue={(option, value) => option.value === value.value || value.id}
                        getOptionLabel={(option) =>
                          entityOptions[attr.field]?.find((o) => o.value === option)?.label ||
                          option.label
                        }
                        options={entityOptions[attr.field] || []} // Pass options as an array
                      />
                    );
                  }
                  if (attr.type === AttributeTypesEnum.date) {
                    return (
                      <RHFDatePicker
                        key={attr.name}
                        name={`customAttributes.${attr.field}`}
                        label={attr.name}
                        type="date"
                        InputLabelProps={{ shrink: true }}
                        error={false}
                      />
                    );
                  }
                  if (attr.type === AttributeTypesEnum.datetime) {
                    return (
                      <RHFDateTimePicker
                        key={attr.name}
                        name={`customAttributes.${attr.field}`}
                        label={attr.name}
                        type="date"
                        InputLabelProps={{ shrink: true }}
                        error={false}
                      />
                    );
                  }
                  return (
                    <RHFTextField
                      key={attr.name}
                      name={`customAttributes.${attr.field}`}
                      label={attr.name}
                    />
                  );
                })}
            </Stack>

            <Stack direction="column" spacing={2} sx={{ mt: 3 }}>
              {entityConfiguration?.attributes
                ?.filter((attr) => attr.type === 'file')
                .map((attr) => (
                  <Box key={attr.name} sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                    <RHFTextField
                      fullWidth
                      label={attr.name}
                      name={`customAttributes.${attr.field}`}
                      value={
                        files.find((file) => file?.field === attr?.field)?.name ||
                        t('fields.noFile')
                      }
                      InputLabelProps={{ shrink: true }}
                      InputProps={{
                        readOnly: true,
                        endAdornment: (
                          <InputAdornment position="end">
                            <Stack spacing={1} direction="row">
                              {!!methods.getValues(`customAttributes.${attr.field}`) && (
                                <Button
                                  variant="outlined"
                                  startIcon={<DeleteIcon />}
                                  onClick={async () => {
                                    try {
                                      await axios.delete(
                                        endpoints.files.delete(
                                          methods.getValues(`customAttributes.${attr.field}`)
                                        )
                                      );
                                      setFiles(files.filter((f) => f.field !== attr.field));
                                      methods.setValue(`customAttributes.${attr.field}`, '');
                                    } catch (e) {
                                      console.error(e);
                                    }
                                  }}
                                >
                                  {t('buttons.delete')}
                                </Button>
                              )}
                              {!methods.getValues(`customAttributes.${attr.field}`) && (
                                <Button
                                  variant="contained"
                                  component="label"
                                  startIcon={<FileUploadIcon />}
                                >
                                  {t('buttons.upload')}
                                  <input
                                    type="file"
                                    hidden
                                    onChange={async (event) => {
                                      const file = event.target.files[0];
                                      if (!file) return;
                                      const formData = new FormData();
                                      formData.append('file', file);
                                      try {
                                        const response = await axios.post(
                                          endpoints.files.upload,
                                          formData,
                                          {
                                            headers: {
                                              'Content-Type': 'multipart/form-data',
                                            },
                                          }
                                        );
                                        setFiles([
                                          ...files.filter((f) => f.field !== attr.field),
                                          {
                                            ...response.data,
                                            field: attr.field,
                                          },
                                        ]);
                                        methods.setValue(
                                          `customAttributes.${attr.field}`,
                                          response.data.fileId
                                        );
                                        enqueueSnackbar(t('successes.fileUploaded'), {
                                          variant: 'success',
                                        });
                                      } catch (error) {
                                        if (error.code) {
                                          enqueueSnackbar(t(`errors.${error.code}`), {
                                            variant: 'error',
                                          });
                                        }
                                        console.error('Error uploading file:', error);
                                      }
                                    }}
                                  />
                                </Button>
                              )}
                            </Stack>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Box>
                ))}
            </Stack>
          </Box>
        </Grid>
      </Grid>
    </form>
  );
}

CustomAttributes.propTypes = {
  current: PropTypes.array,
  entityConfiguration: PropTypes.object,
};
