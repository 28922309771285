export const Features = {
	SendEmailAppointmentCreated: 'feature.send.email.appointment.created',
	SendEmailAppointmentUpdated: 'feature.send.email.appointment.updated',
	SendEmailAppointmentCancelled: 'feature.send.email.appointment.cancelled',
	SendEmailAppointmentReminder: 'feature.send.email.appointment.reminder',
	SendSmsAppointmentCreated: 'feature.send.sms.appointment.created',
	SendSmsAppointmentUpdated: 'feature.send.sms.appointment.updated',
	SendSmsAppointmentCancelled: 'feature.send.sms.appointment.cancelled',
	SendSmsAppointmentReminder: 'feature.send.sms.appointment.reminder',
	SendEmail: 'feature.send.email',
	SendSms: 'feature.send.sms',
	CustomiseBrandingPlatform: 'feature.customise.branding.platform',
	CustomiseBrandingEmail: 'feature.customise.branding.email',
	CustomiseBrandingSms: 'feature.customise.branding.sms',
	CustomiseTemplateEmail: 'feature.customise.template.email',
	CustomiseTemplateSms: 'feature.customise.template.sms',
	AddEmployee: 'feature.add.employee',
	AddCustomer: 'feature.add.customer',
	AddService: 'feature.add.service',
	AddAppointment: 'feature.add.appointment',
	AddGroupAppointment: 'feature.add.appointment.group',
	EditAppointment: 'feature.edit.appointment',
	CancelAppointment: 'feature.cancel.appointment',
	DeleteAppointment: 'feature.delete.appointment',
	Assistant: 'feature.assistant',
	CustomAttributes: 'feature.custom-attributes',
	Storage: 'feature.storage',
	Entities: 'feature.entities',
	AiOperator: 'feature.virtual-operator',
};
