import PropTypes from 'prop-types';
import React, { useCallback, useState } from 'react';
// @mui
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Autocomplete from '@mui/material/Autocomplete';
import Drawer from '@mui/material/Drawer';
// utils
import { fData } from 'src/utils/format-number';
import { fDateTime } from 'src/utils/format-time';
// hooks
import { useBoolean } from 'src/hooks/use-boolean';
// components
import Iconify from 'src/components/iconify';
import Scrollbar from 'src/components/scrollbar';
import FileThumbnail from 'src/components/file-thumbnail';
//
import { useLocales } from '../../locales';
import { useCopyToClipboard } from '../../hooks/use-copy-to-clipboard';
import { enqueueSnackbar } from 'notistack';

// ----------------------------------------------------------------------

export default function FileManagerFileDetails({
  item,
  open,
  //
  onCopyLink,
  onClose,
  onDelete,
  ...other
}) {
  // const { data: fileData, isLoading } = useSWR(endpoints.files.read(item._id), fetcher);

  const { t } = useLocales();
  const { name, size, mimeType, updatedAt, location } = item;
  const {copy} = useCopyToClipboard();

  const toggleTags = useBoolean(true);

  const properties = useBoolean(true);

  const [tags, setTags] = useState(item.tags.slice(0, 3));

  const handleChangeTags = useCallback((newValue) => {
    setTags(newValue);
  }, []);

  const renderTags = (
    <Stack spacing={1.5}>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        sx={{ typography: 'subtitle2' }}
      >
        {t('subtitles.tags')}
        <IconButton size="small" onClick={toggleTags.onToggle}>
          <Iconify
            icon={toggleTags.value ? 'eva:arrow-ios-upward-fill' : 'eva:arrow-ios-downward-fill'}
          />
        </IconButton>
      </Stack>

      {toggleTags.value && (
        <Autocomplete
          multiple
          freeSolo
          options={item.tags.map((option) => option)}
          getOptionLabel={(option) => option}
          defaultValue={item.tags.slice(0, 3)}
          value={tags}
          onChange={(event, newValue) => {
            handleChangeTags(newValue);
          }}
          renderOption={(props, option) => (
            <li {...props} key={option}>
              {option}
            </li>
          )}
          renderTags={(value, getTagProps) =>
            value.map((option, index) => (
              <Chip
                {...getTagProps({ index })}
                size="small"
                variant="soft"
                label={option}
                key={option}
              />
            ))
          }
          renderInput={(params) => (
            <TextField
              {...params}
              helperText={t('helperTexts.tags')}
              placeholder={`+ ${t('fields.addTags')}`}
            />
          )}
        />
      )}
    </Stack>
  );

  const renderProperties = (
    <Stack spacing={1.5}>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        sx={{ typography: 'subtitle2' }}
      >
        {t('subtitles.properties')}
        <IconButton size="small" onClick={properties.onToggle}>
          <Iconify
            icon={properties.value ? 'eva:arrow-ios-upward-fill' : 'eva:arrow-ios-downward-fill'}
          />
        </IconButton>
      </Stack>

      {properties.value && (
        <>
          <Stack direction="row" sx={{ typography: 'caption', textTransform: 'capitalize' }}>
            <Box component="span" sx={{ width: 80, color: 'text.secondary', mr: 2 }}>
              {t('fields.size')}
            </Box>
            {fData(size)} MB
          </Stack>

          <Stack direction="row" sx={{ typography: 'caption', textTransform: 'capitalize' }}>
            <Box component="span" sx={{ width: 80, color: 'text.secondary', mr: 2 }}>
              {t('fields.modified')}
            </Box>
            {fDateTime(updatedAt)}
          </Stack>

          <Stack direction="row" sx={{ typography: 'caption', textTransform: 'capitalize' }}>
            <Box component="span" sx={{ width: 80, color: 'text.secondary', mr: 2 }}>
              {t('fields.type')}
            </Box>
            {mimeType?.split('/')?.[1].toUpperCase()}
          </Stack>
          {/*
            <Stack direction="row" sx={{ typography: 'caption',}}>
                <Box component="span" sx={{ width: 80, color: 'text.secondary', mr: 2 }}>
                    Created by
                </Box>
                {fileData?.createdByUser?.email}
            </Stack>
            */}
        </>
      )}
    </Stack>
  );

  return (
    <>
      <Drawer
        open={open}
        onClose={onClose}
        anchor="right"
        slotProps={{
          backdrop: { invisible: true },
        }}
        PaperProps={{
          sx: { width: 320 },
        }}
        {...other}
      >
        <Scrollbar sx={{ height: 1 }}>
          <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ p: 2.5 }}>
            <Typography variant="h6"> {t('titles.info')} </Typography>
          </Stack>

          <Stack
            spacing={2.5}
            justifyContent="center"
            sx={{
              p: 2.5,
              bgcolor: 'background.neutral',
            }}
          >
            <FileThumbnail
              imageView
              file={location}
              sx={{ width: 64, height: 64 }}
              imgSx={{ borderRadius: 1 }}
            />

            <Typography variant="subtitle1" sx={{ wordBreak: 'break-all' }}>
              {name}
            </Typography>

            <Divider sx={{ borderStyle: 'dashed' }} />

            {renderTags}

            {renderProperties}
          </Stack>
        </Scrollbar>

        <Box sx={{ p: 2.5 }}>
          <Button
            fullWidth
            variant="contained"
            size="large"
            startIcon={<Iconify icon="material-symbols:file-open" />}
            onClick={() => window.open(location, '_blank', 'noopener,noreferrer')}
          >
            {t('buttons.open')}
          </Button>

          <Button
            fullWidth
            variant="soft"
            color="info"
            size="large"
            startIcon={<Iconify icon="material-symbols:share" />}
            onClick={() => {
              copy(location);
              enqueueSnackbar(t('snackbars.fileLinkCopy'), { variant: 'default', anchorOrigin: { horizontal: "center", vertical: "bottom" } });
            }}
          >
            {t('buttons.share')}
          </Button>

          <Button
            fullWidth
            variant="soft"
            color="error"
            size="large"
            startIcon={<Iconify icon="solar:trash-bin-trash-bold" />}
            onClick={() => onDelete(item._id || item.id)}
          >
            {t('buttons.delete')}
          </Button>
        </Box>
      </Drawer>
    </>
  );
}

FileManagerFileDetails.propTypes = {
  item: PropTypes.object,
  onClose: PropTypes.func,
  onCopyLink: PropTypes.func,
  onDelete: PropTypes.func,
  open: PropTypes.bool,
};
