import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { useLocales } from '../../locales';
import { useRouter } from '../../routes/hook';
import { paths } from '../../routes/paths';

const BusinessVerificationRequired = ({ open, onClose }) => {
  const { t } = useLocales();
  const router = useRouter();

  const goToBusinessVerification = () => router.push(paths.dashboard.settings.organisationDetails);

  return (
    <Dialog fullWidth maxWidth="xs" open={open} onClose={onClose}>
      <DialogContent>
        <Container sx={{ mt: 5 }}>
          <Typography variant="h4" align="center" gutterBottom>
            {t('components.pricing.businessVerificationRequired.title')}
          </Typography>
          <Typography variant="body1" gutterBottom>
            {t('components.pricing.businessVerificationRequired.description')}
          </Typography>
          <Typography variant="body2" gutterBottom>
            {t('components.pricing.businessVerificationRequired.explanation')}
          </Typography>
        </Container>
      </DialogContent>

      <DialogActions>
        <Button onClick={onClose}>{t('buttons.cancel')}</Button>
        <Button variant="contained" onClick={() => goToBusinessVerification()} autoFocus>
          {t('components.pricing.businessVerificationRequired.goToVerification')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
BusinessVerificationRequired.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
};

export default BusinessVerificationRequired;
