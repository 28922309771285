import PropTypes from 'prop-types';
import axios, { endpoints } from '../../utils/axios';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import Typography from '@mui/material/Typography';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import { useLocales } from '../../locales';
import { mutate } from 'swr';
import Iconify from '../iconify';
import SaveIcon from '@mui/icons-material/Save';

const deleteFile = async (fileId) => {
  await axios.delete(endpoints.files.delete(fileId));
}

export default function FileDelete({ open, fileId, fileName, handleClose}) {
  const { t } = useLocales();

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>{t('dialogs.titles.fileDelete')}</DialogTitle>
      <DialogContent>
        <Typography>{t('dialogs.subtitles.fileDelete', {fileName})}</Typography>
      </DialogContent>
      <DialogActions>

        <Button
          variant="contained"
          onClick={async () => {
            handleClose();
            await deleteFile(fileId);
            await mutate(endpoints.files.find);
          }}
          color="error"
          startIcon={<Iconify icon="ic:round-delete-forever" />}
        >
          {t('buttons.deleteFile')}
        </Button>
        <Button variant="contained" onClick={handleClose} startIcon={<SaveIcon />}>{t('buttons.keepFile')}</Button>
      </DialogActions>
    </Dialog>
  );
}
FileDelete.propTypes = {
  open: PropTypes.bool,
  fileName: PropTypes.string,
  fileId: PropTypes.string,
  handleClose: PropTypes.func,
}
FileDelete.defaultProps = {
  open: false,
}