// @mui
import Container from '@mui/material/Container';
// routes
import { paths } from 'src/routes/paths';
// components
import { useSettingsContext } from 'src/components/settings';
import CustomBreadcrumbs from 'src/components/custom-breadcrumbs';
//
import EntityCreateForm, { formatEntityName } from './EntityCreateForm';
import { useLocales } from '../../../locales';
import { useParams } from '../../../routes/hook';

export default function EntityCreateContainer() {
    const { t } = useLocales();
  const settings = useSettingsContext();
    const params = useParams();
    const { entity: rawEntity } = params;
    const entity = formatEntityName(rawEntity);

  return (
    <Container maxWidth={settings.themeStretch ? false : 'lg'}>
      <CustomBreadcrumbs
        heading={t('pages.entities.add', { entity })}
        links={[
          {
            name: t('breadcrumbs.dashboard'),
            href: paths.dashboard.root,
          },
          {
            name: entity,
            href: paths.dashboard.entities.root(entity),
          },
        ]}
        sx={{
          mb: { xs: 3, md: 5 },
        }}
      />
        <EntityCreateForm />
    </Container>
  );
}
