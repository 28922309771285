import { Helmet } from 'react-helmet-async';
import { useLocales } from '../../../locales';
import { useAuthContext } from '../../../auth/hooks';
import NoAccessPage from '../../../sections/access/NoAccess';
import CustomerCustomAttributesListView from '../../../sections/customer-custom-attributes/CustomerCustomAttributesListView';
import { canUseFeature } from '../../../utils/feature-control';
import { Features } from '../../../assets/data/features';

export default function CustomAttributesCustomer() {
  const { t } = useLocales();

  const { user, features } = useAuthContext();
  const canAccessFeature = canUseFeature(features, Features.CustomAttributes);
  const hasAdminRole = ['ADMIN', 'MANAGER'].includes(user?.role);
  const canAccess = canAccessFeature && hasAdminRole;

  if (!canAccess) {
    return <NoAccessPage />;
  }

  return (
    <>
      <Helmet>
        <title>
          {t('seo.customAttributes.customer.title')} - {t('appName')}
        </title>
        <meta name="description" content={t('seo.customAttributes.customer.description')} />
      </Helmet>

      <CustomerCustomAttributesListView />
    </>
  );
}
