import PropTypes from 'prop-types';
import { Controller, useFormContext } from 'react-hook-form';
// @mui
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';

// ----------------------------------------------------------------------

export default function RHFAutocomplete({
  name,
  label,
  placeholder,
  helperText,
  onChange,
  ...other
}) {
  const { control, setValue, getValues } = useFormContext();

  const getValue = () => {
    const currentValue = getValues(name);
    if (!currentValue) {
      return other.multiple ? [] : null;
    }
    return currentValue;
  };

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <Autocomplete
          {...field}
          value={getValue()}
          onChange={(event, newValue) => {
            setValue(name, newValue, { shouldValidate: true });
            if (onChange) {
              onChange(event, newValue);
            }
          }}
          renderInput={(params) => (
            <TextField
              variant="filled"
              label={label}
              placeholder={placeholder}
              error={!!error}
              helperText={error ? error?.message : helperText}
              {...params}
            />
          )}
          {...other}
        />
      )}
    />
  );
}

RHFAutocomplete.propTypes = {
  helperText: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
};
