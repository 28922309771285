import React, { useRef } from 'react';
import Stack from '@mui/material/Stack';
import FormProvider, { RHFEditor, RHFTextField } from '../../../../components/hook-form';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { enqueueSnackbar } from 'notistack';
import EmailTemplatePreview from './EmailTemplatePreview';
import PropTypes from 'prop-types';
import LoadingButton from '@mui/lab/LoadingButton';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Chip from '@mui/material/Chip';
import { useLocales } from '../../../../locales';
import Box from '@mui/material/Box';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import IconButton from '@mui/material/IconButton';

const variables = [
  { label: 'variables.customerName', value: '{{customer.name}}' },
  { label: 'variables.customerEmail', value: '{{customer.email}}' },
  { label: 'variables.customerPhoneNumber', value: '{{customer.phoneNumber}}' },
  { label: 'variables.employeeName', value: '{{employee.name}}' },
  { label: 'variables.employeeEmail', value: '{{employee.email}}' },
  { label: 'variables.employeePhoneNumber', value: '{{employee.phoneNumber}}' },
  { label: 'variables.appointmentDate', value: '{{appointment.date}}' },
  { label: 'variables.appointmentTime', value: '{{appointment.time}}' },
  { label: 'variables.appointmentUrl', value: '{{appointment.url}}' },
  { label: 'variables.serviceName', value: '{{service.name}}' },
  { label: 'variables.serviceDuration', value: '{{service.duration}}' },
  { label: 'variables.servicePrice', value: '{{service.price}}' },
  { label: 'variables.serviceCurrency', value: '{{service.currency}}' },
  { label: 'variables.serviceLocation', value: '{{service.location}}' },
  { label: 'variables.organisationName', value: '{{organisation.name}}' },
  { label: 'variables.organisationAddressStreet', value: '{{organisation.address.street}}' },
  { label: 'variables.organisationAddressCity', value: '{{organisation.address.city}}' },
  { label: 'variables.organisationAddressCounty', value: '{{organisation.address.county}}' },
  { label: 'variables.organisationAddressCountry', value: '{{organisation.address.country}}' },
];

const AppointmentTemplate = ({
	                             handleUpdate,
	                             defaultValues,
                             }) => {
	const {t} = useLocales();
	const Schema = Yup.object().shape({
		subject: Yup.string().required().max(70),
		title: Yup.string().required().max(50),
		body: Yup.string().required().max(1000),
		closing: Yup.string().required().max(1000),
	});

	const methods = useForm({
		resolver: yupResolver(Schema),
		defaultValues
	});

	const {
		watch,
		handleSubmit,
	} = methods;
	const values = watch();

	const onSubmit = handleSubmit(async (data) => handleUpdate(data), (errors) => {
		console.error(errors);
	});

	return (
		<FormProvider methods={methods} onSubmit={onSubmit}>
			<Stack spacing={1}>
				<Variables/>
				<RHFTextField name="subject" label={t('fields.subject')} maxChars={70}/>
				<RHFTextField name="title" label={t('fields.title')} maxChars={50}/>

				<RHFEditor template simple name="body" label={t('fields.body')} id="body" maxChars={1000}/>
				<RHFEditor template simple name="closing" label={t('fields.closing')} maxChars={1000}/>
				<LoadingButton variant="contained" type="submit">{t('buttons.update')}</LoadingButton>
				<Divider>{t('titles.previewTemplate')}</Divider>
				<EmailTemplatePreview title={values?.title} body={values?.body} closing={values?.closing}/>
			</Stack>
		</FormProvider>
	);
};
AppointmentTemplate.propTypes = {
	defaultValues: PropTypes.object.isRequired,
	handleUpdate: PropTypes.func.isRequired,
};


const Variables = () => {
	const {t} = useLocales();

	const chipsContainerRef = useRef(null);

	const scroll = (direction) => {
		if (direction === 'left') {
			chipsContainerRef.current.scrollBy({left: -150, behavior: 'smooth'});
		} else {
			chipsContainerRef.current.scrollBy({left: 150, behavior: 'smooth'});
		}
	};

	return (
		<Box sx={{mb: 3}}>
			<Box sx={{pb: 0, mb: 1}}>
				<Typography variant="h5" sx={{m: 0, p: 0}}>{t('titles.variables')}</Typography>
				<Typography variant="caption" sx={{pb: 0}}>{t('subtitles.variables')}</Typography>
			</Box>
			<Box display="flex" alignItems="center">
				<IconButton onClick={() => scroll('left')}>
					<ArrowBackIosIcon/>
				</IconButton>
				<Box
					ref={chipsContainerRef}
					display="flex"
					overflow="auto"
					whiteSpace="nowrap"
					sx={{
						width: '100%', scrollBehavior: 'smooth',
						'&::-webkit-scrollbar': {display: 'none'}, // Hides the scrollbar in WebKit browsers
						'-ms-overflow-style': 'none', // Hides the scrollbar in IE and Edge
						'scrollbar-width': 'none',
					}}

				>
					{variables.map((variable) => (
						<Chip
							label={t(variable.label)}
							key={variable.value}
							sx={{margin: '0px 2px'}}
							onClick={() => {
								navigator.clipboard.writeText(variable.value);
								enqueueSnackbar(t('snackbars.variableCopy', {label: t(variable.label)}), {variant: 'success'});
							}}/>
					))}
				</Box>
				<IconButton onClick={() => scroll('right')}>
					<ArrowForwardIosIcon/>
				</IconButton>
			</Box>
		</Box>
	);
};

export default AppointmentTemplate;
