import PropTypes from 'prop-types';
import {useCallback, useEffect, useState} from 'react';
// routes
import {paths} from 'src/routes/paths';
import {useRouter} from 'src/routes/hook';
//
import {useAuthContext} from '../hooks';
import {useLocation} from "react-router-dom";

// ----------------------------------------------------------------------

const loginPaths = paths.auth.login;
const registerPath = paths.auth.register;

// ----------------------------------------------------------------------

export default function AuthGuard({children, createOrganisation}) {
	const router = useRouter();
	const location = useLocation();
	const isInvitation = location.pathname === "/invitation";

	const {authenticated, user} = useAuthContext();

	const [checked, setChecked] = useState(false);

	const check = useCallback(() => {
		const searchParams = new URLSearchParams({returnTo: `${window.location.pathname}${window.location.search}`}).toString();
		if (!authenticated) {
			const href = isInvitation ? `${registerPath}?${searchParams}` : `${loginPaths}?${searchParams}`;
			router.replace(href);
		} else {
			if (authenticated && !user?.active) {
				router.replace(`${paths.auth.register}?${encodeURIComponent(searchParams)}`);
				return;
			}
			if (authenticated && location.pathname === "/invitation") {
				setChecked(true);
				return;
			}
			if (authenticated && user?.active && createOrganisation) {
				setChecked(true);
				return;
			}
			if (authenticated && user?.active && !user.organisations.length) {
				router.replace(paths.onboarding);
			}
			if (authenticated && user?.active && user.organisations.length && !user?.organisation) {
				router.replace(paths.organisations);
			}

			setChecked(true);
		}
	}, [authenticated, router, createOrganisation, user?.active, user?.organisation, user?.organisations?.length, location, isInvitation]);

	useEffect(() => {
		check();
	}, []);

	if (!checked) {
		return null;
	}

	return <>{children}</>;
}

AuthGuard.propTypes = {
	children: PropTypes.node,
	createOrganisation: PropTypes.bool,
};
