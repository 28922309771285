import PropTypes from 'prop-types';
import {useCallback, useEffect} from 'react';
// routes
import {paths} from 'src/routes/paths';
import {useRouter, useSearchParams} from 'src/routes/hook';
//
import {useAuthContext} from '../hooks';
import {useLocation} from "react-router-dom";

// ----------------------------------------------------------------------

export default function GuestGuard({children}) {
	const router = useRouter();
	const location = useLocation();
	const searchParams = useSearchParams();
	const returnTo = searchParams.get('returnTo');

	const {user, authenticated} = useAuthContext();

	const check = useCallback(() => {
		if (authenticated && user?.active && user?.organisation) {
			router.replace(paths.dashboard.root);
		}
		if (authenticated && location.pathname === "/invitation") {
			return;
		}
		if (authenticated && user?.active && !user.organisation) {
			router.replace(paths.onboarding);
		}
		if (authenticated && !user?.active) {
			router.replace(`${paths.auth.register}${returnTo ? `?returnTo=${encodeURIComponent(returnTo)}` : ''}`);
		}
	}, [user, authenticated, router]);

	useEffect(() => {
		check();
	}, [check]);

	return <>{children}</>;
}

GuestGuard.propTypes = {
	children: PropTypes.node,
};
