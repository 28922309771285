import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import ListItemText from '@mui/material/ListItemText';
import useSWR from 'swr';
import { endpoints, fetcher } from '../../utils/axios';
import { blue, grey } from '@mui/material/colors';
import PropTypes from 'prop-types';
import { useLocales } from '../../locales';
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import React, { useEffect, useState } from 'react';
import DialogContent from '@mui/material/DialogContent';
import ArticleIcon from '@mui/icons-material/Article';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';
import List from '@mui/material/List';

export default function FileSelect({ open, handleClose, handleSelect }) {
  const { t } = useLocales();
  const { data = [], isLoading } = useSWR(endpoints.files.find, fetcher);
  const [files, setFiles] = useState(data);

  useEffect(() => {
    if (!isLoading) {
      setFiles(data);
    }
  }, [isLoading]);

  return (
    <Dialog onClose={handleClose} open={open} fullWidth maxWidth="sm">
      <DialogTitle>{t('dialogs.titles.fileSelect')}</DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={(theme) => ({
          position: 'absolute',
          right: 8,
          top: 8,
          color: theme.palette.grey[500],
        })}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent>
        <TextField
          fullWidth
          label={t('fields.search')}
          variant="outlined"
          sx={{ mb: 2, mt: 1 }}
          onChange={(e) => {
            const value = e.target.value.toLowerCase();
            console.log('here', value);
            if(value) {
              setFiles(
                data.filter((file) => file.name.toLowerCase().includes(value))
              );
            }
          }}
          InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
          }}
        />
        { isLoading && (
          <Box sx={{ width: '100%' }}>
            <LinearProgress />
          </Box>
        )}
        <List sx={{ pt: 0 }}>
          {files.map((file) => (
            <ListItem disablePadding key={file.id}>
              <ListItemButton onClick={() => handleSelect(file.id, file.name)}>
                <ListItemAvatar>
                  <Avatar sx={{ bgcolor: grey[100], color: blue[600] }}>
                    <ArticleIcon />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText primary={file.name} />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={handleClose} fullWidth>
          {t('buttons.cancel')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
FileSelect.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  handleSelect: PropTypes.func,
};
FileSelect.defaultProps = {
  open: false,
  handleClose: () => {},
  handleSelect: () => {},
}
