import { useCallback, useState } from 'react';
// @mui
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
// utils
import { fTimestamp } from 'src/utils/format-time';
// _mock
// hooks
import { useBoolean } from 'src/hooks/use-boolean';
// components
import Iconify from 'src/components/iconify';
import EmptyContent from 'src/components/empty-content';
import { ConfirmDialog } from 'src/components/custom-dialog';
import { useSettingsContext } from 'src/components/settings';
import { getComparator, useTable } from 'src/components/table';
//
import FileManagerTable from '../file-manager-table';
import FileManagerFilters from '../file-manager-filters';
import FileManagerFiltersResult from '../file-manager-filters-result';
import FileManagerNewFolderDialog from '../file-manager-new-folder-dialog';
import { useLocales } from '../../../locales';
import useSWR from 'swr';
import axios, { endpoints, fetcher } from '../../../utils/axios';
import { useFileTypes } from '../../../hooks/use-file-types';

// ----------------------------------------------------------------------

const defaultFilters = {
	name: '',
	type: [],
	startDate: null,
	endDate: null,
};

// ----------------------------------------------------------------------

export default function FileManagerView() {
	const table = useTable({defaultRowsPerPage: 10, defaultOrderBy: 'modifiedAt', defaultOrder: 'desc'});
	const {t} = useLocales();

	const settings = useSettingsContext();
	const fileTypeOptions = useFileTypes();

	const openDateRange = useBoolean();

	const confirm = useBoolean();

	const upload = useBoolean();

	const {data: tableData = [], mutate} = useSWR(endpoints.files.find, fetcher);

	const [filters, setFilters] = useState(defaultFilters);

	const dateError =
		filters.startDate && filters.endDate
			? filters.startDate.getTime() > filters.endDate.getTime()
			: false;

	const dataFiltered = applyFilter({
		inputData: tableData,
		comparator: getComparator(table.order, table.orderBy),
		filters,
		dateError,
		fileTypeOptions,
	});

	const dataInPage = dataFiltered.slice(
		table.page * table.rowsPerPage,
		table.page * table.rowsPerPage + table.rowsPerPage
	);

	const canReset =
		!!filters.name || !!filters.type.length || (!!filters.startDate && !!filters.endDate);

	const notFound = (!dataFiltered.length && canReset) || !dataFiltered.length;

	const handleFilters = useCallback(
		(name, value) => {
			table.onResetPage();
			setFilters((prevState) => ({
				...prevState,
				[name]: value,
			}));
		},
		[table]
	);

	const handleDeleteItem = useCallback(
		async (id) => {
			try {
				await axios.delete(endpoints.files.delete(id));
			} catch (error) {
				console.error(error);
			}
			const deleteRow = tableData.filter((row) => row.id !== id);
			await mutate(deleteRow);
			table.onUpdatePageDeleteRow(dataInPage.length);
		},
		// eslint-disable-next-line
		[dataInPage.length, table, tableData]
	);

	const handleDeleteItems = useCallback(async () => {
		const deleteRows = tableData.filter((row) => !table.selected.includes(row.id));
		try {
			await axios.delete(endpoints.files.deleteBulk, {params: {ids: table.selected}})
		} catch(e) {
			console.error(e)
		}
		await mutate(deleteRows);

		table.onUpdatePageDeleteRows({
			totalRows: tableData.length,
			totalRowsInPage: dataInPage.length,
			totalRowsFiltered: dataFiltered.length,
		});
		// eslint-disable-next-line
	}, [dataFiltered.length, dataInPage.length, table, tableData]);

	const handleResetFilters = useCallback(() => {
		setFilters(defaultFilters);
	}, []);


	const renderFilters = (
		<Stack
			spacing={2}
			direction={{xs: 'column', md: 'row'}}
			alignItems={{xs: 'flex-end', md: 'center'}}
		>
			<FileManagerFilters
				openDateRange={openDateRange.value}
				onCloseDateRange={openDateRange.onFalse}
				onOpenDateRange={openDateRange.onTrue}
				//
				filters={filters}
				onFilters={handleFilters}
				//
				dateError={dateError}
				typeOptions={fileTypeOptions}
			/>
		</Stack>
	);

	const renderResults = (
		<FileManagerFiltersResult
			filters={filters}
			onResetFilters={handleResetFilters}
			//
			canReset={canReset}
			onFilters={handleFilters}
			//
			results={dataFiltered.length}
		/>
	);

	return (
		<>
			<Container maxWidth={settings.themeStretch ? false : 'lg'}>
				<Stack direction="row" alignItems="center" justifyContent="space-between">
					<Typography variant="h4">{t('pages.files.title')}</Typography>
					<Button
						variant="contained"
						startIcon={<Iconify icon="eva:cloud-upload-fill"/>}
						onClick={upload.onTrue}
					>
						{t('buttons.upload')}
					</Button>
				</Stack>

				<Stack
					spacing={2.5}
					sx={{
						my: {xs: 3, md: 5},
					}}
				>
					{renderFilters}

					{canReset && renderResults}
				</Stack>

				{notFound ? (
					<EmptyContent
						filled
						title="No Data"
						sx={{
							py: 10,
						}}
					/>
				) : (
					<FileManagerTable
						table={table}
						tableData={tableData}
						dataFiltered={dataFiltered}
						onDeleteRow={handleDeleteItem}
						notFound={notFound}
						onOpenConfirm={confirm.onTrue}
					/>
				)}
			</Container>

			<FileManagerNewFolderDialog open={upload.value} onClose={upload.onFalse}  />

			<ConfirmDialog
				open={confirm.value}
				onClose={confirm.onFalse}
				title="Delete"
				content={
					<>
						Are you sure want to delete <strong> {table.selected.length} </strong> items?
					</>
				}
				action={
					<Button
						variant="contained"
						color="error"
						onClick={() => {
							handleDeleteItems();
							confirm.onFalse();
						}}
					>
						Delete
					</Button>
				}
			/>
		</>
	);
}

// ----------------------------------------------------------------------

function applyFilter({inputData, comparator, filters, dateError, fileTypeOptions}) {
	const {name, type, startDate, endDate} = filters;

	const stabilizedThis = inputData.map((el, index) => [el, index]);

	stabilizedThis.sort((a, b) => {
		const order = comparator(a[0], b[0]);
		if (order !== 0) return order;
		return b[1] - a[1];
	});

	inputData = stabilizedThis.map((el) => el[0]);

	if (name) {
		inputData = inputData.filter(
			(file) => file.name.toLowerCase().indexOf(name.toLowerCase()) !== -1
		);
	}

	if (type.length) {
		const allAcceptedTypes = fileTypeOptions.filter(format => type.includes(format.kind)).flatMap(format => format.types)
		inputData = inputData.filter((file) => allAcceptedTypes.includes(file.mimeType));
	}

	if (!dateError) {
		if (startDate && endDate) {
			inputData = inputData.filter(
				(file) =>
					fTimestamp(file.createdAt) >= fTimestamp(startDate) &&
					fTimestamp(file.createdAt) <= fTimestamp(endDate)
			);
		}
	}

	return inputData;
}
