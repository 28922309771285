import PropTypes from 'prop-types';
import { format } from 'date-fns';
import { useCallback, useState } from 'react';
// @mui
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import MenuItem from '@mui/material/MenuItem';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import ListItemText from '@mui/material/ListItemText';
import { alpha, useTheme } from '@mui/material/styles';
import TableRow, { tableRowClasses } from '@mui/material/TableRow';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
// hooks
import { useBoolean } from 'src/hooks/use-boolean';
import { useDoubleClick } from 'src/hooks/use-double-click';
import { useCopyToClipboard } from 'src/hooks/use-copy-to-clipboard';
// utils
import { fData } from 'src/utils/format-number';
// components
import Iconify from 'src/components/iconify';
import CustomPopover, { usePopover } from 'src/components/custom-popover';
import { useSnackbar } from 'src/components/snackbar';
import { ConfirmDialog } from 'src/components/custom-dialog';
import FileThumbnail from 'src/components/file-thumbnail';
//
import FileManagerShareDialog from './file-manager-share-dialog';
import FileManagerFileDetails from './file-manager-file-details';
import { useLocales } from '../../locales';
import axios, { endpoints } from '../../utils/axios';

// ----------------------------------------------------------------------

export default function FileManagerTableRow({row, selected, onSelectRow, onDeleteRow}) {
	const theme = useTheme();
	const { t } = useLocales();

	const {name, size, mimeType, updatedAt, shared, location} = row;

	const {enqueueSnackbar} = useSnackbar();

	const {copy} = useCopyToClipboard();

	const [inviteEmail, setInviteEmail] = useState('');

	const details = useBoolean();

	const share = useBoolean();

	const confirm = useBoolean();

	const popover = usePopover();

	const handleChangeInvite = useCallback((event) => {
		setInviteEmail(event.target.value);
	}, []);

	const handleClick = useDoubleClick({
		click: () => {
			details.onTrue();
		},
		doubleClick: () => console.info('DOUBLE CLICK'),
	});

	const handleCopy = useCallback(async () => {
		copy(row.location);
		enqueueSnackbar(t('snackbars.fileLinkCopy'));
	}, [copy, enqueueSnackbar, row.location, t]);

	const defaultStyles = {
		borderTop: `solid 1px ${alpha(theme.palette.grey[500], 0.16)}`,
		borderBottom: `solid 1px ${alpha(theme.palette.grey[500], 0.16)}`,
		'&:first-of-type': {
			borderTopLeftRadius: 16,
			borderBottomLeftRadius: 16,
			borderLeft: `solid 1px ${alpha(theme.palette.grey[500], 0.16)}`,
		},
		'&:last-of-type': {
			borderTopRightRadius: 16,
			borderBottomRightRadius: 16,
			borderRight: `solid 1px ${alpha(theme.palette.grey[500], 0.16)}`,
		},
	};

	return (
		<>
			<TableRow
				selected={selected}
				sx={{
					borderRadius: 2,
					[`&.${tableRowClasses.selected}, &:hover`]: {
						backgroundColor: 'background.paper',
						boxShadow: theme.customShadows.z20,
						transition: theme.transitions.create(['background-color', 'box-shadow'], {
							duration: theme.transitions.duration.shortest,
						}),
						'&:hover': {
							backgroundColor: 'background.paper',
							boxShadow: theme.customShadows.z20,
						},
					},
					[`& .${tableCellClasses.root}`]: {
						...defaultStyles,
					},
					...(details.value && {
						[`& .${tableCellClasses.root}`]: {
							...defaultStyles,
						},
					}),
				}}
			>
				<TableCell padding="checkbox">
					<Checkbox
						checked={selected}
						onDoubleClick={() => console.info('ON DOUBLE CLICK')}
						onClick={onSelectRow}
					/>
				</TableCell>

				<TableCell onClick={handleClick}>
					<Stack direction="row" alignItems="center" spacing={2}>
						<FileThumbnail file={name} sx={{width: 36, height: 36}}/>

						<Typography
							noWrap
							variant="inherit"
							sx={{
								maxWidth: 360,
								cursor: 'pointer',
								...(details.value && {fontWeight: 'fontWeightBold'}),
							}}
						>
							{name}
						</Typography>
					</Stack>
				</TableCell>

				<TableCell onClick={handleClick} sx={{whiteSpace: 'nowrap'}}>
					{fData(size)} MB
				</TableCell>

				<TableCell onClick={handleClick} sx={{whiteSpace: 'nowrap'}}>
					{mimeType?.split('/')?.[1].toUpperCase()}
				</TableCell>

				<TableCell onClick={handleClick} sx={{whiteSpace: 'nowrap'}}>
					<ListItemText
						primary={format(new Date(updatedAt), 'dd MMM yyyy')}
						secondary={format(new Date(updatedAt), 'p')}
						primaryTypographyProps={{typography: 'body2'}}
						secondaryTypographyProps={{
							mt: 0.5,
							component: 'span',
							typography: 'caption',
						}}
					/>
				</TableCell>

				<TableCell
					align="right"
					sx={{
						px: 1,
						whiteSpace: 'nowrap',
					}}
				>
					<IconButton color={popover.open ? 'inherit' : 'default'} onClick={popover.onOpen}>
						<Iconify icon="eva:more-vertical-fill"/>
					</IconButton>
				</TableCell>
			</TableRow>

			<CustomPopover
				open={popover.open}
				onClose={popover.onClose}
				arrow="right-top"
				sx={{width: 160}}
			>
				<MenuItem
					onClick={() => {
						popover.onClose();
						window.open(row.location, "_blank", "noopener,noreferrer")
					}}
				>
					<Iconify icon="material-symbols:file-open"/>
					{t('buttons.open')}
				</MenuItem>
				<MenuItem
					onClick={() => {
						popover.onClose();
						handleCopy();
					}}
				>
					<Iconify icon="eva:link-2-fill"/>
					{t('buttons.copyURL')}
				</MenuItem>

				<Divider sx={{borderStyle: 'dashed'}}/>

				<MenuItem
					onClick={() => {
						confirm.onTrue();
						popover.onClose();
					}}
					sx={{color: 'error.main'}}
				>
					<Iconify icon="solar:trash-bin-trash-bold"/>
					Delete
				</MenuItem>
			</CustomPopover>

			<FileManagerFileDetails
				item={row}
				onCopyLink={handleCopy}
				open={details.value}
				onClose={details.onFalse}
				onDelete={onDeleteRow}
			/>

			<FileManagerShareDialog
				open={share.value}
				shared={shared}
				inviteEmail={inviteEmail}
				onChangeInvite={handleChangeInvite}
				onCopyLink={handleCopy}
				onClose={() => {
					share.onFalse();
					setInviteEmail('');
				}}
			/>

			<ConfirmDialog
				open={confirm.value}
				onClose={confirm.onFalse}
				title="Delete"
				content="Are you sure want to delete?"
				action={
					<Button variant="contained" color="error" onClick={(...args) => {
						confirm.onFalse();
						onDeleteRow(...args);
					}}>
						{t('buttons.delete')}
					</Button>
				}
			/>
		</>
	);
}

FileManagerTableRow.propTypes = {
	onDeleteRow: PropTypes.func,
	onSelectRow: PropTypes.func,
	row: PropTypes.object,
	selected: PropTypes.bool,
};
