import PropTypes from 'prop-types';
import * as Yup from 'yup';
import {useForm} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
// @mui
import LoadingButton from '@mui/lab/LoadingButton';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Unstable_Grid2';
// utils
// routes
import {paths} from 'src/routes/paths';
import {useRouter} from 'src/routes/hook';
// assets
// components
import {useSnackbar} from 'src/components/snackbar';
import FormProvider, {RHFAutocomplete, RHFSwitch, RHFTextField,} from 'src/components/hook-form';
import axios, {endpoints} from "../../../utils/axios";
import {useLocales} from "../../../locales";
import {useAuthContext} from "../../../auth/hooks";
import React from "react";
import {phoneNumberRegex} from "../../../utils/regex";
import {canSendSms} from "../../../data/twilio";
import {mutate} from "swr";
import CustomAttributes from '../../../components/custom-attributes/CustomAttributes';
import Typography from '@mui/material/Typography';
import { AttributeTypesEnum } from '../../../data/attribute';
import dayjs from 'dayjs';

// ----------------------------------------------------------------------

const create = async (data) => {
	const response = await axios.post(endpoints.customers.create, data);
	return response.data;
}

const patch = async (id, data) => {
	const response = await axios.patch(endpoints.customers.patch(id), data);
	return response.data;
}

const defaultValues = {
	name: '',
	email: '',
	phoneNumber: '',
	active: true,
}

export default function CustomersCreateForm({current, handleUpdate, removeCard, customAttributes}) {
	const {configuration} = useAuthContext();
	const {t} = useLocales();
	const router = useRouter();

	const {enqueueSnackbar} = useSnackbar();

	const Schema = Yup.object().shape({
		name: Yup.string().required().max(100),
		email: Yup.string().email(),
		phoneNumber: Yup.string().notRequired().test('is-valid-phone', t('validations.invalidPhoneNumber'), value => !value || phoneNumberRegex.test(value)),
		active: Yup.boolean(),
		timezone: Yup.object({
			value: Yup.string().required(), label: Yup.string().required(),
		}),
		customAttributes: Yup.object({
			field: Yup.string(),
			value: Yup.string().max(5000),
		}),
	});

	const methods = useForm({
		resolver: yupResolver(Schema),
		defaultValues: current ? {
			...current,
			timezone: {
				label: current?.timezone || null, value: current?.timezone || null
			},
			customAttributes: current.customAttributes ? current.customAttributes?.reduce((acc, item) => {
				const config = customAttributes.find((a) => a.field === item.field);
				if (config.type === AttributeTypesEnum.entity) {
					acc[item.field] = { label: item.label, value: item.value };
					return acc;
				}
				if (config.type === AttributeTypesEnum.entities) {
					acc[item.field] = item.value.values;
					return acc;
				}
				if (
					[AttributeTypesEnum.date, AttributeTypesEnum.datetime].includes(config.type)
				) {
					item.value = dayjs(item.value);
					return acc;
				}
				acc[item.field] = item.value;
				return acc;
			}, {}) : {},
		} : {
			...defaultValues,
			timezone: {
				value: configuration.timezone || Intl.DateTimeFormat().resolvedOptions().timeZone || '',
				label: configuration.timezone || Intl.DateTimeFormat().resolvedOptions().timeZone || ''
			},
		}
	});

	const {
		reset,
		handleSubmit,
		formState: {isSubmitting},
		watch,
	} = methods;

	const phoneNumberValue = watch('phoneNumber');

	const onSubmit = handleSubmit(async (data) => {
		try {
			const body = {
				...data,
				timezone: data.timezone.value,
				customAttributes: Object.entries(data.customAttributes || {})
					.filter(([_field, value]) => value?.value || (value && JSON.stringify(value) !== '{}')) // Ensure the value or value.value exists
					.map(([field, value]) => ({
						field,
						value: Array.isArray(value) ? value.map((v) => v.value || v.id) : value?.value || value, // Handle nested value objects
					})),
			};
			if (current) {
				const id = current._id || current.id;
				const updated = await patch(id, body);
				handleUpdate(updated);
				await mutate(endpoints.customers.find, (prevData = []) => prevData?.map((item) => item.id === id ? updated : item), false);
				await mutate(endpoints.customers.get(id), updated);
				await mutate(endpoints.customers.view(id), updated);
			} else {
				const customer = await create(body);
				await mutate(endpoints.customers.find, (prevData = []) => [...prevData, customer], false);
			}
			enqueueSnackbar(current ? t('pages.customers.addCustomer.snackbar.update') : t('pages.customers.addCustomer.snackbar.add'));
			if (!current) {
				reset();
				router.push(paths.dashboard.customers.root);
			}
		} catch (error) {
			console.error(error);
		}
	});

	const Container = removeCard || current ? Box : Card;

	const hasCustomAttributes = customAttributes?.length > 0;

	return (
		<FormProvider methods={methods} onSubmit={onSubmit}>
			<Grid container spacing={3}>
				<Grid xs={12} md={12}>
					<Container sx={{p: 3}}>
						<Box
							rowGap={3}
							columnGap={2}
							display="grid"
							gridTemplateColumns={{
								xs: 'repeat(1, 1fr)',
								sm: 'repeat(2, 1fr)',
							}}
						>
							<RHFTextField name="name" label={t('fields.name')}/>
							<RHFTextField name="email" label={t('fields.email')}
							              type="email"/>
							<RHFTextField
								name="phoneNumber"
								label={t('fields.phoneNumber')}
								color="error"
								helperText={phoneNumberValue && (canSendSms(phoneNumberValue) === false && t('errors.notSupportedCountry'))}
							/>
							<RHFAutocomplete
								sx={{mb: 3}}
								name="timezone"
								label={t('fields.timezone')}
								options={Intl.supportedValuesOf('timeZone').map(timezone => ({
									value: timezone,
									label: timezone
								}))}
								getOptionLabel={(option) => option.label}
								isOptionEqualToValue={(option, value) => option.value === value.value}
								renderOption={(props, option) => (<li {...props} key={option.value}>
									{option.label}
								</li>)}
							/>
						</Box>

						{hasCustomAttributes && <Typography variant="subtitle1" sx={{ m: '25px 5px' }}>{t('titles.customAttributes')}</Typography>}
						<CustomAttributes
							current={current?.customAttributes}
							entityConfiguration={{ entity: 'customer', attributes: customAttributes }}
						/>

						<Stack alignItems="flex-end" sx={{mt: 3}} direction="row" justifyContent="flex-end">
							<RHFSwitch name="active" label={t('fields.active')}
							           sx={{marginLeft: 1}}/>
							<LoadingButton type="submit" variant="contained" loading={isSubmitting}>
								{!current ? t('buttons.add') : t('buttons.update')}
							</LoadingButton>
						</Stack>
					</Container>
				</Grid>
			</Grid>
		</FormProvider>
	);
}

CustomersCreateForm.propTypes = {
	current: PropTypes.object,
	handleUpdate: PropTypes.func,
	removeCard: PropTypes.bool,
	customAttributes: PropTypes.array,
};
