import React, { useContext } from 'react';
import { endpoints, fetcher } from 'src/utils/axios';

// @mui
import Card from '@mui/material/Card';
import Container from '@mui/material/Container';
import { capitalize } from '@mui/material/utils';
// routes
import { paths } from 'src/routes/paths';
import { useParams } from 'src/routes/hook';
// _mock
// hooks
// components
import { useSettingsContext } from 'src/components/settings';
import CustomBreadcrumbs from 'src/components/custom-breadcrumbs';
//
import { useLocales } from '../../locales';
import useSWR from 'swr';
import { AuthContext } from '../../auth/context/jwt';
import { Actions, hasPermission } from '../../utils/roles';
import NoAccessPage from '../access/NoAccess';
import mongoose from 'mongoose';
import Grid from '@mui/material/Unstable_Grid2';
import Box from '@mui/material/Box';
import { formatEntityName } from './create/EntityCreateForm';
import { ViewField } from '../../components/fields/ViewField';
import { AttributeTypesEnum } from '../../data/attribute';

// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

export default function EntityView() {
  const { user } = useContext(AuthContext);
  const { t } = useLocales();
  const params = useParams();
  const { entity: rawEntity, id: recordId } = params;
  const entity = formatEntityName(rawEntity);

  const canViewList = hasPermission(user, Actions.entities.view);

  const settings = useSettingsContext();

  const { data: entityConfiguration } = useSWR(
    endpoints.entityConfigurations.type(entity),
    fetcher
  );
  const { data: record = { attributes: [] }, isLoading } = useSWR(
    endpoints.entities.view(entity, recordId),
    fetcher
  );

  if (!canViewList) {
    return <NoAccessPage />;
  }

  if (!mongoose.Types.ObjectId.isValid(recordId)) {
    return <NoAccessPage />;
  }

  if (isLoading) {
    return null;
  }

  const getLabel = (field) =>
    entityConfiguration.attributes.find((attr) => attr.field === field)?.name;

  const getValue = (field) => {
    const type = entityConfiguration.attributes.find((attr) => attr.field === field)?.type;
    if ([AttributeTypesEnum.entity, AttributeTypesEnum.entities].includes(type)) {
      return record[field];
    }

    return record.attributes.find((attr) => attr.field === field)?.value;
  };

  return (
    <Container maxWidth={settings.themeStretch ? false : 'lg'}>
      <CustomBreadcrumbs
        heading={record.name}
        links={[
          { name: t('breadcrumbs.dashboard'), href: paths.dashboard.root },
          {
            name: capitalize(entity),
            href: paths.dashboard.entities.root(entity),
          },
          {
            name: recordId,
            href: paths.dashboard.entities.view(entity, recordId),
          },
        ]}
        sx={{
          mb: { xs: 3, md: 5 },
        }}
      />

      <Grid container spacing={3}>
        <Grid xs={12} md={12}>
          <Card sx={{ p: 3 }}>
            <Box
              rowGap={3}
              columnGap={2}
              display="grid"
              gridTemplateColumns={{
                xs: 'repeat(1, 1fr)',
                sm: 'repeat(2, 1fr)',
              }}
            >
              {record.attributes.map((attr) => (
                <ViewField
                  label={getLabel(attr.field)}
                  value={getValue(attr.field)}
                  attribute={attr}
                  attributeConfiguration={entityConfiguration.attributes.find(
                    (a) => a.field === attr.field
                  )}
                />
              ))}
            </Box>
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
}
