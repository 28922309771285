import { useEffect, useState } from 'react';
// @mui
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import BusinessIcon from '@mui/icons-material/Business';
// components;
import Box from '@mui/material/Box';

import {
  Avatar,
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { grey } from '@mui/material/colors';
import axios, { endpoints } from '../../utils/axios';
import { useAuthContext } from '../../auth/hooks';
import { useRouter } from '../../routes/hook';
import { PATH_AFTER_ORGANISATION_SELECT } from '../../config-global';
import { paths } from '../../routes/paths';
import { useLocales } from '../../locales';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

const getOrganisations = async () => {
  const response = await axios.get(endpoints.user.organisations);
  return response.data;
};

export default function Organisations() {
	const { t } = useLocales();
	const [loading, setLoading] = useState(false);
	const {selectOrganisation} = useAuthContext();
	const [organisations, setOrganisations] = useState([]);
	const router = useRouter();

	useEffect(() => {
		async function fetchData() {
			const data = await getOrganisations();
			setOrganisations(data);
		}
		fetchData()
	}, []);

	const handleSelectOrganisation = async (id) => {
		try {
			setLoading(true);
			await selectOrganisation?.(id);
			router.push(PATH_AFTER_ORGANISATION_SELECT);
			setLoading(false);
		} catch(e) {
			console.log(e);
		}

	}

	return (
		<Box sx={{width: '100%'}}>
			<Backdrop
				sx={(theme) => ({ color: theme.palette.primary, zIndex: theme.zIndex.drawer + 1, backgroundColor: 'rgba(0, 0, 0, 0.2)'})}
				open={loading}
			>
				<CircularProgress color="inherit" />
			</Backdrop>
			<Stack spacing={2} sx={{mb: 5}}>
				<Typography variant="h4" align="center">{t('pages.organisations.title')}</Typography>
			</Stack>
			<List sx={{ pt: 0 }}>
				{organisations.map(({ id, name }) => (
					<ListItem disableGutters key={name}>
						<ListItemButton onClick={() => handleSelectOrganisation(id) }>
							<ListItemAvatar>
								<Avatar sx={{ bgcolor: grey[100], color: grey[600] }}>
									<BusinessIcon />
								</Avatar>
							</ListItemAvatar>
							<ListItemText primary={name} />
						</ListItemButton>
					</ListItem>
				))}
				<ListItem disableGutters>
					<ListItemButton
						onClick={() => {} }
					>
						<ListItemAvatar>
							<Avatar>
								<AddIcon />
							</Avatar>
						</ListItemAvatar>
						<ListItemText primary={t('pages.organisations.add')} onClick={() => router.push(paths.organisation.create)} />
					</ListItemButton>
				</ListItem>
			</List>
		</Box>
	);
}
