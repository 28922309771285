import * as React from 'react';
import { useMemo } from 'react';
import Alert from '@mui/material/Alert';
import Avatar from '@mui/material/Avatar';
import PropTypes from 'prop-types';
import {
  getEmail,
  getName,
  getPhoneNumber,
  getServiceName,
  getServicePrice,
} from '../../../../data/mock';
import { replacePlaceholders } from '../../../../data/template';
import { useLocales } from '../../../../locales';
import moment from 'moment';
import { useAuthContext } from '../../../../auth/hooks';

export default function SmsTemplatePreview({ body }) {
  const { organisation } = useAuthContext();
  const { currentLang } = useLocales();

  const data = useMemo(
    () => ({
      employee: {
        name: getName(currentLang.value),
        email: getEmail(currentLang.value),
        phoneNumber: getPhoneNumber(currentLang.value),
      },
      service: {
        name: getServiceName(currentLang.value),
        price: getServicePrice(currentLang.value),
        duration: 60,
      },
      customer: {
        name: getName(currentLang.value),
        email: getEmail(currentLang.value),
        phoneNumber: getPhoneNumber(currentLang.value),
      },
      appointment: {
        date: moment().locale(currentLang.value).format('dddd, D MMMM').toString(),
        time: moment().locale(currentLang.value).format('HH:mm').toString(),
      },
      organisation,
    }),
    [currentLang.value, organisation]
  );

  body = replacePlaceholders(body, data);

  return (
    <Alert icon={<Avatar>BZ</Avatar>} severity="primary">
      {body}
    </Alert>
  );
}
SmsTemplatePreview.propTypes = {
	body: PropTypes.string.isRequired,
}
