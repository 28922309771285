import PropTypes from 'prop-types';
import {useCallback, useEffect, useMemo, useReducer} from 'react';
// utils
import axios, {endpoints} from 'src/utils/axios';
//
import {AuthContext} from './auth-context';
import {isValidToken, refreshSession, setSession} from './utils';
import {io} from "socket.io-client";
import {useSWRConfig} from "swr";

export const STORAGE_KEY_TOKEN = 'accessToken';

// ----------------------------------------------------------------------

// NOTE:
// We only build demo at basic level.
// Customer will need to do some extra handling yourself if you want to extend the logic and other features...

// ----------------------------------------------------------------------

const initialState = {
	user: null,
	organisation: null,
	configuration: null,
	subscription: null,
	features: [],
	loading: true,
};

const reducer = (state, action) => {
	if (action.type === 'INITIAL') {
		return {
			loading: false,
			user: action.payload.user,
			organisation: action.payload.organisation,
			configuration: action.payload.configuration,
			features: action.payload.features,
			subscription: action.payload.subscription,
		};
	}
	if (action.type === 'FEATURES') {
		return {
			...state,
			features: action.payload.features,
		};

	}
	if (action.type === 'SUBSCRIPTION') {
		return {
			...state,
			subscription: action.payload.subscription,
		};
	}
	if (action.type === 'LOGIN') {
		return {
			...state,
			user: action.payload.user,
		};
	}
	if (action.type === 'REGISTER') {
		return {
			...state,
			user: action.payload.user,
		};
	}
	if (action.type === 'SELECT_ORGANISATION') {
		return {
			...state,
			user: action.payload.user,
			organisation: action.payload.organisation,
			configuration: action.payload.configuration,
			features: action.payload.features,
			subscription: action.payload.subscription,
		};
	}
	if (action.type === 'SUBMIT_VERIFICATION') {
		return {
			...state,
			configuration: action.payload.configuration,
		}
	}
	if (action.type === 'ACTIVATE') {
		return {
			...state,
			user: action.payload.user,
		};
	}
	if (action.type === 'LOGOUT') {
		return {
			...state,
			user: null,
			organisation: null,
			configuration: null,
		};
	}
	return state;
};

// ----------------------------------------------------------------------

let socket;

export function AuthProvider({children}) {
	const {mutate} = useSWRConfig()
	const [state, dispatch] = useReducer(reducer, initialState);

	const initialize = useCallback(async () => {
		try {
			const accessToken = sessionStorage.getItem(STORAGE_KEY_TOKEN) || localStorage.getItem(STORAGE_KEY_TOKEN);
			if (accessToken && isValidToken(accessToken)) {
				refreshSession(accessToken);
				const userResponse = await axios.get(endpoints.auth.me);
				const user = userResponse.data;
				setSession(accessToken, user.email);
				const organisationResponse = await axios.get(endpoints.organisation.me);
				const organisation = organisationResponse.data;
				const configurationResponse = await axios.get(endpoints.configurations.get);
				const configuration = configurationResponse.data;
				const featuresResponse = await axios.get(endpoints.subscriptions.quotas);
				const features = featuresResponse.data;
				const subscriptionResponse = await axios.get(endpoints.subscriptions.me);
				const subscription = subscriptionResponse.data;

				dispatch({
					type: 'INITIAL',
					payload: {
						user,
						organisation,
						configuration,
						features,
						subscription,
					},
				});
			} else {
				dispatch({
					type: 'INITIAL',
					payload: {
						user: null,
						organisation: null,
						configuration: null,
						features: null,
					},
				});
			}
		} catch (error) {
			console.error(error);
			dispatch({
				type: 'INITIAL',
				payload: {
					user: null,
					organisation: null,
					configuration: null,
				},
			});
		}
	}, []);

	useEffect(() => {
		initialize();
	}, [initialize]);

	// LOGIN
	const login = useCallback(async (email, password, remember) => {
		const data = {
			email,
			password,
		};
		const response = await axios.post(endpoints.auth.login, data);
		const {accessToken, user} = response.data;
		setSession(accessToken, user.email, remember);
		dispatch({
			type: 'LOGIN',
			payload: {
				user,
			},
		});
	}, []);

	// REGISTER
	const register = useCallback(async (data) => {
		const response = await axios.post(endpoints.auth.register, data);

		const {accessToken, user} = response.data;

		setSession(accessToken, user.email);

		dispatch({
			type: 'REGISTER',
			payload: {
				user,
			},
		});
	}, []);

	const fetchSubscription = useCallback(async () => {
		const subscriptionResponse = await axios.get(endpoints.subscriptions.me);
		const subscription = subscriptionResponse.data;

		dispatch({
			type: 'SUBSCRIPTION',
			payload: {
				subscription,
			},
		});
	}, []);

	const fetchQuota = useCallback(async () => {
		const subscriptionResponse = await axios.get(endpoints.subscriptions.quotas);
		const features = subscriptionResponse.data;

		dispatch({
			type: 'FEATURES',
			payload: {
				features,
			},
		});
	}, []);

	const selectOrganisation = useCallback(async (data) => {
		const clearSWRCache = () => mutate(
			() => true,
			undefined,
			{revalidate: false}
		)
		await clearSWRCache();
		const response = await axios.get(endpoints.organisation.select(data));
		const {accessToken, user} = response.data;
		setSession(accessToken, user.email);
		const organisationResponse = await axios.get(endpoints.organisation.me);
		const organisation = organisationResponse.data;
		const configurationResponse = await axios.get(endpoints.configurations.get);
		const configuration = configurationResponse.data;
		const featuresResponse = await axios.get(endpoints.subscriptions.quotas);
		const features = featuresResponse.data;
		const subscriptionResponse = await axios.get(endpoints.subscriptions.me);
		const subscription = subscriptionResponse.data;

		dispatch({
			type: 'SELECT_ORGANISATION',
			payload: {
				user,
				organisation,
				configuration,
				features,
				subscription,
			},
		});
	}, []);

	const activate = useCallback(async (data) => {
		const response = await axios.post(endpoints.auth.activate, data);

		const {accessToken, user} = response.data;

		setSession(accessToken, user.email);

		dispatch({
			type: 'ACTIVATE',
			payload: {
				user,
			},
		});
	}, []);

	const submitVerification = useCallback(async (formData) => {
		const response = await axios.post(endpoints.organisation.verification, formData, {
			headers: {
				'Content-Type': 'multipart/form-data',
			},
		});
		const configuration = response.data;
		dispatch({
			type: 'SUBMIT_VERIFICATION',
			payload: {
				configuration,
			},
		});
	}, []);

	// LOGOUT
	const logout = useCallback(async () => {
		setSession(null);
		dispatch({
			type: 'LOGOUT',
		});
	}, []);

	// ----------------------------------------------------------------------

	const checkAuthenticated = state.user ? 'authenticated' : 'unauthenticated';

	const status = state.loading ? 'loading' : checkAuthenticated;

	useEffect(() => {
		if (socket) {
			socket.off('subscription:quota');
		}
		if (state.organisation) {
			socket = io(`${process.env.REACT_APP_HOST_API}/subscriptions`, {
				path: '/quota',
				query: {token: sessionStorage.getItem(STORAGE_KEY_TOKEN) || localStorage.getItem(STORAGE_KEY_TOKEN)},
				transports: ['websocket', 'polling'],
			});
			socket.on('subscription:quota', async (newQuotas) => {
				await fetchSubscription();
				dispatch({
					type: 'FEATURES',
					payload: {
						features: newQuotas,
					},
				})
			});
		}

		return () => {
			if (socket) {
				socket.off('subscription:quota');
			}
		};
	}, [state.organisation, state.subscription]);

	const memoizedValue = useMemo(
		() => ({
			user: state.user,
			method: 'jwt',
			loading: status === 'loading',
			authenticated: status === 'authenticated',
			unauthenticated: status === 'unauthenticated',
			organisation: state.organisation,
			configuration: state.configuration,
			//
			login,
			register,
			selectOrganisation,
			activate,
			logout,
			features: state.features,
			subscription: state.subscription,
			fetchSubscription,
			fetchQuota,
			submitVerification,
		}),
		[activate, login, logout, register, state.user, status, selectOrganisation, state.organisation, state.configuration, state.features, state.subscription, fetchSubscription, fetchQuota, submitVerification]
	);

	return <AuthContext.Provider value={memoizedValue}>{children}</AuthContext.Provider>;
}

AuthProvider.propTypes = {
	children: PropTypes.node,
};
