import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import {initReactI18next} from 'react-i18next';
// utils
import {localStorageGetItem} from 'src/utils/storage-available';
//
import {defaultLang} from './config-lang';
//
import translationRo from './langs/ro.json';
import translationEn from './langs/en.json';
import translationFr from './langs/fr.json';
import translationVi from './langs/vi.json';
import translationCn from './langs/cn.json';
import translationAr from './langs/ar.json';
import translationEs from './langs/es.json';
import translationIt from './langs/it.json';
import translationDe from './langs/de.json';
import translationPl from './langs/pl.json';

import privacyPolicyRo from './namespaces/privacy-policy/ro.json';
import privacyPolicyEn from './namespaces/privacy-policy/en.json';
import privacyPolicyFr from './namespaces/privacy-policy/fr.json';
import privacyPolicyVi from './namespaces/privacy-policy/vi.json';
import privacyPolicyCn from './namespaces/privacy-policy/cn.json';
import privacyPolicyAr from './namespaces/privacy-policy/ar.json';
import privacyPolicyEs from './namespaces/privacy-policy/es.json';
import privacyPolicyIt from './namespaces/privacy-policy/it.json';
import privacyPolicyDe from './namespaces/privacy-policy/de.json';
import privacyPolicyPl from './namespaces/privacy-policy/pl.json';

import termsAndConditionsRo from './namespaces/terms-and-conditions/ro.json';
import termsAndConditionsEn from './namespaces/terms-and-conditions/en.json';
import termsAndConditionsFr from './namespaces/terms-and-conditions/fr.json';
import termsAndConditionsVi from './namespaces/terms-and-conditions/vi.json';
import termsAndConditionsCn from './namespaces/terms-and-conditions/cn.json';
import termsAndConditionsAr from './namespaces/terms-and-conditions/ar.json';
import termsAndConditionsEs from './namespaces/terms-and-conditions/es.json';
import termsAndConditionsIt from './namespaces/terms-and-conditions/it.json';
import termsAndConditionsDe from './namespaces/terms-and-conditions/de.json';
import termsAndConditionsPl from './namespaces/terms-and-conditions/pl.json';

import plansRo from './namespaces/plans/ro.json';
import plansEn from './namespaces/plans/en.json';
import plansFr from './namespaces/plans/fr.json';
import plansVi from './namespaces/plans/vi.json';
import plansCn from './namespaces/plans/cn.json';
import plansAr from './namespaces/plans/ar.json';
import plansEs from './namespaces/plans/es.json';
import plansIt from './namespaces/plans/it.json';
import plansDe from './namespaces/plans/de.json';
import plansPl from './namespaces/plans/pl.json';
// ----------------------------------------------------------------------

const lng = localStorageGetItem('i18nextLng', defaultLang.value);

i18n
	.use(LanguageDetector)
	.use(initReactI18next)
	.init({
		resources: {
			ro: {
				translations: translationRo,
				termsAndConditions: termsAndConditionsRo,
				privacyPolicy: privacyPolicyRo,
				plans: plansRo,
			},
			en: {
				translations: translationEn,
				termsAndConditions: termsAndConditionsEn,
				privacyPolicy: privacyPolicyEn,
				plans: plansEn,
			},
			fr: {
				translations: translationFr,
				termsAndConditions: termsAndConditionsFr,
				privacyPolicy: privacyPolicyFr,
				plans: plansFr,
			},
			vi: {
				translations: translationVi,
				termsAndConditions: termsAndConditionsVi,
				privacyPolicy: privacyPolicyVi,
				plans: plansVi,
			},
			cn: {
				translations: translationCn,
				termsAndConditions: termsAndConditionsCn,
				privacyPolicy: privacyPolicyCn,
				plans: plansCn,
			},
			ar: {
				translations: translationAr,
				termsAndConditions: termsAndConditionsAr,
				privacyPolicy: privacyPolicyAr,
				plans: plansAr,
			},
			es: {
				translations: translationEs,
				termsAndConditions: termsAndConditionsEs,
				privacyPolicy: privacyPolicyEs,
				plans: plansEs,
			},
			it: {
				translations: translationIt,
				termsAndConditions: termsAndConditionsIt,
				privacyPolicy: privacyPolicyIt,
				plans: plansIt,
			},
			de: {
				translations: translationDe,
				termsAndConditions: termsAndConditionsDe,
				privacyPolicy: privacyPolicyDe,
				plansAr
			},
			pl: {
				translations: translationPl,
				termsAndConditions: termsAndConditionsPl,
				privacyPolicy: privacyPolicyPl,
				plans: plansPl,
			},
		},
		lng,
		fallbackLng: lng,
		debug: false,
		ns: ['translations', 'termsAndConditions', 'privacyPolicy', 'plans'],
		defaultNS: 'translations',
		detection: {
			order: ['querystring', 'cookie', 'localStorage', 'navigator'],
			caches: ['localStorage', 'cookie'],
			lookupQuerystring: 'lng',
		},
		interpolation: {
			escapeValue: false,
		},
	});

export default i18n;
