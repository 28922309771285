import PropTypes from 'prop-types';
import React, {useCallback, useEffect, useState} from 'react';
// @mui
import Stack from '@mui/material/Stack';
import Badge from '@mui/material/Badge';
import Drawer from '@mui/material/Drawer';
import Divider from '@mui/material/Divider';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
// utils
// components
import Iconify from 'src/components/iconify';
import {useLocales} from "../../locales";
import FormProvider, {RHFAutocomplete, RHFSelect, RHFTextField} from "../../components/hook-form";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import * as Yup from "yup";
import Container from "@mui/material/Container";
import LoadingButton from "@mui/lab/LoadingButton";
import {AttributeTypesEnum} from "../../data/attribute";
import MenuItem from "@mui/material/MenuItem";
import RHFDateTimePicker from "../../components/hook-form/rhf-datetimepicker";
import RHFDatePicker from "../../components/hook-form/rhf-datepicker";
import dayjs from "dayjs";
import {useParams} from "../../routes/hook";
import useSWR from "swr";
import axios, {endpoints, fetcher} from "../../utils/axios";

// ----------------------------------------------------------------------
export const formatEntityName = (entity) => entity.replace(/-/g, ' ');

export default function EntityFilters({
	                                      attributes,
	                                      open,
	                                      onClose,
	                                      //
	                                      filters,
	                                      onFilters,
	                                      //
	                                      canReset,
	                                      onResetFilters,
	                                      handleSubmitFilters
                                      }) {
	const {t} = useLocales();
	const params = useParams();
	const { entity: rawEntity } = params;
	const entity = formatEntityName(rawEntity);

	const [entityOptions, setEntityOptions] = useState({}); // Stores options for each entity attribute

	const { data: entityConfiguration } = useSWR(endpoints.entityConfigurations.type(entity), fetcher);

	const Schema = Yup.object();


	const methods = useForm({
		resolver: yupResolver(Schema),
		defaultValues: filters,
	});

	const {
		handleSubmit,
	} = methods;

	const onSubmit = handleSubmit(async () => {
		handleSubmitFilters();
		onClose();
	});

	const handleFilterChange = useCallback(
		(key, newValue, override = true) => {
			if (newValue.target?.value) {
				newValue = newValue.target.value;
			}
			onFilters(key, override ? newValue : {...filters[key], ...newValue});
		},
		[onFilters, filters]
	);

	const fetchEntities = async (entityConfig) => {
		if (entityConfig.isDefault) {
			const response = await axios.get(entityConfig.path);
			return response.data;
		}
		const response = await axios.get(endpoints.entities.find(entityConfig.entity));
		return response.data.map(item => {
			const attributesObject = item.attributes.reduce((acc, attr) => {
				acc[attr.field] = attr.value;
				return acc;
			}, {});
			return {
				entity: item.entity,
				...attributesObject,
				active: item.active,
				deleted: item.deleted,
				organisation: item.organisation,
				createdAt: item.createdAt,
				updatedAt: item.updatedAt,
				id: item.id || item._id
			};
		});
	}

	const getEntityOptions = async (entityConfig) => {
		try {
			const { data: currentEntityConfiguration } = await axios.get(endpoints.entityConfigurations.type(entityConfig.entity));
			const primaryAttributesField = currentEntityConfiguration.attributes
				.filter(attr => attr.primary)
				.map(attr => attr.field);

			const currentEntities = await fetchEntities(currentEntityConfiguration);

			return currentEntities.map((e) => {
				const label = primaryAttributesField
					.map(field => e[field])
					.filter(value => value)
					.join(' | ');

				return {
					label: label || e.id, // Fallback to e.id if no primary attributes are present
					value: e._id || e.id,
				};
			});
		} catch (e) {
			console.error(e);
		}
		return [];
	}

	const fetchEntityOptions = async (entityConfig) => {
		try {
			const options = await getEntityOptions(entityConfig);
			setEntityOptions((prevOptions) => ({
				...prevOptions,
				[entityConfig.field]: options,
			}));
		} catch (error) {
			console.error("Failed to fetch entity options:", error);
		}
	};

	useEffect(() => {
		if (entityConfiguration?.attributes) {
			entityConfiguration.attributes
				.filter(attr => attr.type === AttributeTypesEnum.entity)
				.forEach(fetchEntityOptions);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [entityConfiguration]);

	const renderHead = (
		<Stack
			direction="row"
			alignItems="center"
			justifyContent="space-between"
			sx={{p: 2.5}}
		>
			<Typography variant="h6" sx={{flexGrow: 1}}>
				{t('calendarToolbar.filters.title')}
			</Typography>

			<Tooltip title={t('buttons.reset')}>
				<IconButton onClick={onResetFilters}>
					<Badge color="error" variant="dot" invisible={!canReset}>
						<Iconify icon="solar:restart-bold"/>
					</Badge>
				</IconButton>
			</Tooltip>

			<IconButton onClick={onClose}>
				<Iconify icon="mingcute:close-line"/>
			</IconButton>
		</Stack>
	);

	return (
		<Drawer
			anchor="right"
			open={open}
			onClose={onClose}
			slotProps={{
				backdrop: {invisible: true},
			}}
			PaperProps={{
				sx: {width: 400},
			}}
		>
			{renderHead}

			<Divider sx={{borderStyle: 'dashed'}}/>
			<Container>
				<FormProvider methods={methods} onSubmit={onSubmit}>
					<Stack spacing={2} sx={{mb: 3, mt: 3}}>
						{attributes?.map(attr => {
							if (attr.type === AttributeTypesEnum.datetime) {
								return (
									<Stack spacing={1} direction="column" sx={{ mb: 1}}>
										<Typography variant="subtitle2">{attr.name}</Typography>
										<Stack spacing={2} direction="row">
											<RHFDateTimePicker
												key={attr.name}
												name={`${attr.field}.gte`}
												label="From date"
												type="date"
												InputLabelProps={{shrink: true}}
												error={false}
												value={filters[attr.field]?.gte ? dayjs(filters[attr.field]?.gte) : null}
												onChange={(newValue) => handleFilterChange(attr.field, {gte: newValue}, false)}
											/>

											<RHFDateTimePicker
												key={attr.name}
												name={`${attr.field}.lte`}
												label="To date"
												type="date"
												InputLabelProps={{shrink: true}}
												error={false}
												value={filters[attr.field]?.lte ? dayjs(filters[attr.field]?.lte) : null}
												onChange={(newValue) => handleFilterChange(attr.field, {lte: newValue}, false)}
											/>
										</Stack>
									</Stack>
								)
							}
							if (attr.type === AttributeTypesEnum.date) {
								return (
									<Stack spacing={1} direction="column">
										<Typography variant="subtitle2">{attr.name}</Typography>
										<Stack spacing={2} direction="row">
											<RHFDatePicker
												key={attr.name}
												name={`${attr.field}.gte`}
												label="From date"
												type="date"
												InputLabelProps={{shrink: true}}
												error={false}
												onChange={(newValue) => handleFilterChange(attr.field, {gte: newValue}, false)}
												value={filters[attr.field]?.gte ? dayjs(filters[attr.field]?.gte) : null}
											/>

											<RHFDatePicker
												key={attr.name}
												name={`${attr.field}.lte`}
												label="To date"
												type="date"
												InputLabelProps={{shrink: true}}
												error={false}
												onChange={(newValue) => handleFilterChange(attr.field, {lte: newValue}, false)}
												value={filters[attr.field]?.lte ? dayjs(filters[attr.field]?.lte) : null}
											/>
										</Stack>
									</Stack>
								)
							}
							if (attr.type === AttributeTypesEnum.dropdown) {
								return (
									<RHFSelect
										fullWidth
										sx={{textTransform: 'none'}}
										key={attr.name}
										name={attr.field}
										label={attr.name}
										onChange={(newValue) => handleFilterChange(attr.field, newValue)}
										value={filters[attr.field]}
									>
										{attr.options.map((option) => (
											<MenuItem key={option} value={option}>
												{option}
											</MenuItem>
										))}
									</RHFSelect>
								);
							}
							if (attr.type === AttributeTypesEnum.text) {
								return (
									<RHFTextField
										fullWidth
										key={attr.name}
										name={attr.field}
										value={filters[attr.field]}
										label={attr.name}
										onChange={(newValue) => handleFilterChange(attr.field, newValue.target.value)}
									/>
								)
							}
							if (attr.type === AttributeTypesEnum.entity) {
								return (
									<RHFAutocomplete
										key={attr.name}
										name={attr.field}
										value={filters[attr.field]}
										label={attr.name}
										onChange={(_event, newValue) => handleFilterChange(attr.field, newValue)}
										getOptionLabel={(option) => (entityOptions[attr.field]?.find((o) => o.value === option))?.label || option.label}
										options={entityOptions[attr.field] || []} // Pass options as an array
									/>
								);
							}
							return null;
						})}
					</Stack>

					<LoadingButton
						fullWidth
						type="submit"
						variant="contained"
					>

						{t('buttons.filter')}
					</LoadingButton>
				</FormProvider>
			</Container>

		</Drawer>
	);
}

EntityFilters.propTypes = {
	attributes: PropTypes.array,
	canReset: PropTypes.bool,
	filters: PropTypes.object,
	onClose: PropTypes.func,
	onFilters: PropTypes.func,
	onResetFilters: PropTypes.func,
	open: PropTypes.bool,
	handleSubmitFilters: PropTypes.func,
};
