import {Helmet} from 'react-helmet-async';
import {useLocales} from "../../../locales";
import CustomisationView from "../../../sections/settings/CustomisationView";
import {useAuthContext} from "../../../auth/hooks";
import NoAccessPage from "../../../sections/access/NoAccess";
import { canUseFeature } from '../../../utils/feature-control';
import { Features } from '../../../assets/data/features';

export default function CustomisationPage() {
	const {t} = useLocales();

	const { user, features } = useAuthContext();
	const canAccessFeature = canUseFeature(features, Features.CustomiseBrandingPlatform);
	const hasAdminRole = ['ADMIN', 'MANAGER'].includes(user?.role)
	const canAccess = canAccessFeature && hasAdminRole;

	if (!canAccess) {
		return (
			<NoAccessPage/>
		);
	}

	if (!canAccess) {
		return (
			<NoAccessPage/>
		);
	}

	return (
		<>
			<Helmet>
				<title>{t('seo.customisation.title')} - {t('appName')}</title>
				<meta name="description" content={t('seo.customisation.description')}/>
			</Helmet>

			<CustomisationView/>
		</>
	);
}
